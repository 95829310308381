import { useEffect, useState } from "react";
import { Tooltip } from "antd/lib";
import { initOTPless } from "../../utils/initOtpless";
import {
  Menu,
  Image,
  Typography,
  Col,
  Row,
  Space,
  Badge,
  Dropdown,
} from "antd";
import {
  Link,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";
//@ts-ignore
import logo from "../../assets/images/darkLogo.svg";
import lilypadLogo from "../../assets/LilypadLogo.png";
//@ts-ignore
import bag from "../../assets/images/bag.svg";
//@ts-ignore
import userDark from "../../assets/images/userDark.svg";
//@ts-ignore
import burgerIcon from "../../assets/images/burgerIcon.svg";
//@ts-ignore
import battery from "../../assets/images/battery.svg";
import "./layoutStyle/navbar.css";
import { useDispatch, useSelector } from "react-redux";
import "./menu.css";
import { getUserCartList, userLogin } from "../../api/user";
import { updateCartItem } from "../../Redux/actionTypes";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaRegUserCircle } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
const { Text } = Typography;
const Navbar = () => {
  const location = useLocation();
  const [userData, setUserData] = useState(
    localStorage.getItem("otpless_user_token")
  );
  const authToken = localStorage.getItem("auth_token");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);
  const [current, setCurrent] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.waNumber,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    setUserData(otplessUser);
    closeModal();
    navigate(location.pathname);
    window.location.reload();
  };

  const openModal = () => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
    // modalContainer !== null ? (modalContainer.style.display = "flex") : "";
  };

  const closeModal = () => {
    const modalContainer = document.getElementById("modalContainer");
    // if (e.target === modalContainer) {
    // 	modalContainer ? (modalContainer.style.display = 'none') : ''
    // }
    if (modalContainer) {
      modalContainer.style.display = "none";
    }
    const script = document.getElementById("otplessIdScript");
    if (script) {
      script.remove();
    }
    if (userData === null) {
      if (
        location.pathname === "/user/profile" ||
        location.pathname === "/user/orders" ||
        location.pathname === "/user/address"
      ) {
        navigate("/");
      }
    }
    // script ? script.remove() : "";
  };

  useEffect(() => {
    setUserData(localStorage.getItem("otpless_user_token"));
  }, [location.pathname]);

  useEffect(() => {
    if (userData === null && location.pathname === "/user/profile") {
      openModal();
    } else {
      closeModal();
    }
    // else if (location.pathname !== '/profile' && userData) {
    // 	closeModal()
    // }
  }, [userData, location.pathname]);

  const cartItems = useSelector((state) => state.cart.cartItems);

  useEffect(() => {
    // Event handler to update windowWidth state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleReload = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("reload", handleReload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("reload", handleReload);
    };
  }, [windowWidth]);

  const handleMenuClick = (e) => {
    const userDetails = JSON.parse(localStorage.getItem("user_details"));
    const username = userDetails?.email
      ? userDetails?.email
      : userDetails?.mobile;

    if (e.key === "blog") {
      window.open(
        `https://blog.lilypad.co.in/user?login=${username}&redirect=blog`,
        "_blank"
      );
    } else if (e.key === "forum") {
      window.open(
        `https://blog.lilypad.co.in/user?login=${username}&redirect=forum`,
        "_blank"
      );
    } else if (e.key === "news") {
      window.open(
        `https://blog.lilypad.co.in/user?login=${username}&redirect=news`,
        "_blank"
      );
    }
  };

  const items = [
    {
      label: "Blog",
      key: "blog",
      icon: <></>,
    },
    {
      label: "Forum",
      key: "forum",
      icon: <></>,
    },
    {
      label: "News",
      key: "news",
      icon: <></>,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const navItems = [
    {
      label: <Text className="nav-item">Home</Text>,
      key: "home",
    },
    {
      label: <Text className="nav-item">Find My EV</Text>,
      key: "products",
    },

    {
      label: <Text className="nav-item">Our Story</Text>,
      key: "aboutUs",
    },
    {
      label: <Text className="nav-item">Why Lilypad</Text>,
      key: "vision",
    },
    {
      label: <Text className="nav-item">Contact Us</Text>,
      key: "contactUs",
    },
    // {
    //   label: (
    //     <Dropdown menu={menuProps}>
    //       <Text className="nav-item">Community</Text>
    //     </Dropdown>
    //   ),
    //   key: "community",
    // },
    {
      label: (
        <Tooltip
          placement="bottom"
          title={"Powering up"}
          style={{ fontFamily: "AvenirNextLTPro" }}
        >
          <Image src={battery} width={45} preview={false} />
        </Tooltip>
      ),
      key: "battery",
    },
    {
      label: (
        <Badge count={cartItems.length} >
          <Tooltip
            placement="bottom"
            title={"Cart"}
            style={{ fontFamily: "AvenirNextLTPro" }}
          >  <FaShoppingCart style={{ fontSize: "1rem", color: '#fff', paddingTop: "7px" }} /></Tooltip>
        </Badge>
      ),
      key: "addToBag",
    },

    {
      label: (
        <>  <Tooltip
          placement="top"
          title={"Profile"}
          style={{ fontFamily: "AvenirNextLTPro" }}
        >
          <Link to={"/user/profile"}>

            <FaRegUserCircle style={{ fontSize: "1rem", color: '#fff', paddingTop: "7px" }} />
          </Link>
        </Tooltip>
          <div className="model-init">
            <div
              className="modal-container"
              id="modalContainer"
              onClick={closeModal}
            >
              <div className="modal">
                <span className="close-icon" onClick={closeModal}>
                  &times;
                </span>
                <div id="otpless-login-page" custom="true"></div>
              </div>
            </div>
          </div>
        </>
      ),
      key: "profile",
    },
  ];

  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === "aboutUs") {
      navigate("/about");
    } else if (e.key === "contactUs") {
      navigate("/contact");
    } else if (e.key === "vision") {
      navigate("/vision");
    } else if (e.key === "home") {
      navigate("/");
    } else if (e.key === "products") {
      navigate("/products");
    } else if (e.key === "addToBag") {
      navigate("/cart");
    } else if (e.key === "battery") {
      navigate("/powering-up");
    }
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleNavigation = (url) => {
    setShowMenu(!showMenu);
    navigate(url);
  };

  useEffect(() => {
    // Define an array of keys corresponding to your routes
    const keys = {
      "/": "home",
      "/about": "aboutUs",
      "/contact": "contactUs",
      "/products": "products",
      "/vision": "vision",
      "/powering-up": "battery",
    };
    // Get the current pathname
    const { pathname } = location;

    // Update the 'current' state based on the pathname
    setCurrent(keys[pathname]);
  }, [location]);

  return (
    <>
      {windowWidth > 1190 ? (
        <nav className="navbar" style={{ boxShadow: "none" }}>
          <Row style={{ alignItems: "center" }}>
            <Col xs={0} md={5} className="nav-logo-container">
              <Image
                preview={false}
                onClick={() => navigate("/")}
                src={lilypadLogo}
                className="nav-logo"
              />
            </Col>
            <Col xs={24} md={19} lg={19}>
              <Menu
                className="navbar-menu"
                onClick={onClick}
                mode="horizontal"
                defaultSelectedKeys={["1"]}
                items={navItems}
                selectedKeys={[current]}
              />
            </Col>
          </Row>
        </nav>
      ) : (
        <>
          <Row className="navbar" style={{ justifyContent: 'space-between' }}>
            <Col span={5} className="nav-logo-container">
              <Image
                preview={false}
                onClick={() => navigate("/")}
                src={lilypadLogo}
                className="nav-logo"
              />
            </Col>
            <Col span={17}>
              <Row
                justify="end"
                align="middle"
                className="mobile-navbar"
                gutter={30}
              >
                <Col>
                  <Link to="/cart">
                    <Badge count={cartItems.length}>
                      <FaShoppingCart style={{ color: "#fff", fontSize: "1rem" }} />
                    </Badge>
                  </Link>
                </Col>
                <Col>
                  <Link to={"/user/profile"}>
                    <FaRegUserCircle style={{ color: "#fff", fontSize: "1rem" }} />
                  </Link>
                  <div className="model-init">
                    <div
                      className="modal-container"
                      id="modalContainer"
                      onClick={closeModal}
                    >
                      <div className="modal">
                        <span className="close-icon" onClick={closeModal}>
                          &times;
                        </span>
                        <div id="otpless-login-page" custom="true"></div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Link to="/powering-up">
                    <Tooltip placement="bottom" title={"Powering up"}>
                      <Image src={battery} width={45} preview={false} />
                    </Tooltip>
                  </Link>
                </Col>
                <Col onClick={handleShowMenu}>
                  <RxHamburgerMenu style={{ color: "#fff", fontSize: "1rem" }} />
                </Col>
              </Row>
            </Col>
            {showMenu && (
              <Space direction="vertical" className="space-container">
                <Col
                  className="space-item"
                  onClick={() => handleNavigation("/")}
                >
                  <Text className="space-text">Home</Text>
                </Col>
                <Col
                  className="space-item"
                  onClick={() => handleNavigation("/products")}
                >
                  <Text className="space-text">Find My EV</Text>
                </Col>
                <Col
                  className="space-item"
                  onClick={() => handleNavigation("/about")}
                >
                  <Text className="space-text">Our Story</Text>
                </Col>
                <Col
                  className="space-item"
                  onClick={() => handleNavigation("/vision")}
                >
                  <Text className="space-text">Why Lilypad</Text>
                </Col>
                <Col
                  className="space-item"
                  onClick={() => handleNavigation("/contact")}
                >
                  <Text className="space-text">Contact</Text>
                </Col>
                {/* <Col className="space-item">
                  <Dropdown menu={menuProps}>
                    <Text className="space-text">Community</Text>
                  </Dropdown>
                </Col> */}
              </Space>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default Navbar;
