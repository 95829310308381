import FirstSection from '../Components/Contact/FirstSection';
import SecondSection from '../Components/Contact/SecondSection';

const Contact = () => {
	return (
		<div style={{ backgroundColor: '#1d1e18' }}>
			<FirstSection />
			<SecondSection />
		</div>
	);
};

export default Contact;
