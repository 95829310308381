import React from 'react';
import { Col, Row, Typography, Image } from 'antd';

//@ts-ignore
import vector12 from '../../assets/images/Vector12.svg';
//@ts-ignore
import image1 from '../../assets/images/image1.svg';
//@ts-ignore
import image2 from '../../assets/images/Image2.svg';
import './aboutStyle/fourthSection.css';
import FifthSection from './FifthSection';

const { Text, Paragraph } = Typography;

const FourthSection = () => {
	return (
		<>
			<Row className="about-fourth-section-container">
				<Col span={24} className="about-top-container">
					<Paragraph className="about-fourth-title">
						The Lilypad Promise.
					</Paragraph>
					<Paragraph className="about-fourth-description">
						With an eclectic mix of electric vehicles and accessories, we fuse
						fun with function, and convenience with sustainability. We stand by
						the belief that making the eco-conscious choice should be as
						thrilling as it is responsible.
					</Paragraph>
					<Paragraph className="about-fourth-description">
						The Lilypad Promise is our commitment to this vision. It’s about
						ensuring every ride is not just a journey, but a step forward for
						the planet.
					</Paragraph>
					<Paragraph className="about-fourth-description">
						Join us, and let’s ride into a greener tomorrow together.
					</Paragraph>
				</Col>
			</Row>
			{/* <Row className='about-fourth-section-container'>
            <Col span={24} className=''>
                <FifthSection />
            </Col>
        </Row> */}
		</>
	);
};

export default FourthSection;
