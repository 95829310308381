import React from 'react'
import './style.css'

const Dimensions = ({ productData }) => {
    return (
        <>
            <table className='overview-table'>
                <tr>
                    <td>&#9679; Total Weight</td>
                    <td>{productData.totalWeight ? `${productData.totalWeight} kg` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Boot Space</td>
                    <td>{productData.bootSpace ? `${productData.bootSpace} L` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Length</td>
                    <td>{productData.length ? `${productData.length} mm` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Width</td>
                    <td>{productData.width ? `${productData.width} mm` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Height</td>
                    <td>{productData.height ? `${productData.height} mm` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Seat Length</td>
                    <td>{productData.seatLength ? `${productData.seatLength} mm` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Ground Clearance</td>
                    <td>{productData.groundClearance ? `${productData.groundClearance} mm` : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Wheel Base</td>
                    <td>{productData.wheelbase ? `${productData.wheelbase} mm` : "-"}</td>
                </tr>
            </table>
        </>
    )
}

export default Dimensions