import { Button, Space, Dropdown } from 'antd';

import {
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import './style/productInputs.css';
import { useState } from 'react';

const ProductSortInput = ({ onTextEntered, onSortingChange }) => {

  const [priceAscending, setPriceAscending] = useState(true)
  const [discountAscending, setDiscountAscending] = useState(true)
  const [ratingAscending, setRatingAscending] = useState(true)
  const [availabiltyAscending, setAvailablityAscending] = useState(true)

  const handleMenuClick = (e) => {
    e.key === "price" && setPriceAscending((prev) => !prev)
    e.key === "discount" && setDiscountAscending((prev) => !prev)
    e.key === "rating" && setRatingAscending((prev) => !prev)
    e.key === "Available" && setAvailablityAscending((prev) => !prev)

    onSortingChange(e.key);
  };
  const items = [
    {
      label: 'Price',
      key: "price",
      icon: priceAscending ? <UpOutlined /> : <DownOutlined />,
    },
    {
      label: 'Discount',
      key: "discount",
      icon: discountAscending ? <UpOutlined /> : <DownOutlined />,
    },
    {
      label: 'Rating',
      key: "rating",
      icon: ratingAscending ? <UpOutlined /> : <DownOutlined />,
    },
    {
      label: 'Availablity',
      key: "availableLocations",
      icon: availabiltyAscending ? <UpOutlined /> : <DownOutlined />,
    }
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <Space>
        <Dropdown menu={menuProps} className='product-sort-input'>
          <Button>
            <Space>
              Sort by
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </>
  );
};

export default ProductSortInput;
