import React from 'react';
import { Row, Col, Typography, Button, Image, Space, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
//@ts-ignore
import frame1 from '../../assets/images/Frame1.svg';
//@ts-ignore
import logo from '../../assets/images/lilypadLogo.png';
import './layoutStyle/footer.css';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa6';

const { Text } = Typography;

const Footer = () => {
	const navigate = useNavigate();

	return (
		<Row className="footer-container">
			<Col span={24}>
				<Row className="footer-top-container">
					<Col xs={24} lg={7} className="footer-logo-container">
						<Col className="footer-logo-sub-container">
							<Image src={logo} preview={false} width={170} className="logo" />
							<div>
								<Text className="footer-logo-text">
									Accelerate. Sustainable. Energy.
								</Text>
							</div>
						</Col>
					</Col>

					<Col xs={24} lg={5} className="footer-menu-container">
						<div className="quick-links">
							<Text className="quick-links-text">Quick Links</Text>
							<hr className="footer-divider" />
						</div>
						<Text
							className="footer-menu-text"
							onClick={() => navigate('/vision')}
						>
							Why Lilypad
						</Text>
						<Text
							className="footer-menu-text"
							onClick={() => navigate('/about')}
						>
							Our Story
						</Text>
						<Text
							className="footer-menu-text"
							onClick={() => navigate('/contact')}
						>
							Contact Us
						</Text>
					</Col>

					<Col
						xs={24}
						lg={5}
						className="footer-menu-container footer-contact-us-menu"
					>
						<Space direction="vertical" size={15}>
							<div className="quick-links">
								<Text className="quick-links-text">Contact Us</Text>
								<hr className="footer-divider" />
							</div>
							<Text className="footer-menu-text">support@lilypad.co.in</Text>
							<Text className="footer-menu-text">+91 9906360313</Text>
							<div style={{ width: '250px' }}>
								<Text className="footer-menu-text">
									HD-396 at WeWork Berger Delhi One, Sector 16B, C-001/A2,
									Gautam Buddha Nagar, Noida, UP 20130
								</Text>
							</div>
						</Space>
					</Col>

					<Col
						xs={24}
						lg={5}
						className="footer-menu-container footer-legel-terms-menu"
					>
						<Space direction="vertical" size={15}>
							<Text
								className="footer-menu-text"
								onClick={() => navigate('/privacy-policy')}
							>
								Privacy Policy
							</Text>
							<Text
								className="footer-menu-text"
								onClick={() => navigate('/terms-and-condition')}
							>
								Terms & Conditions
							</Text>
						</Space>
					</Col>

					<Col xs={24} lg={7} className="footer-menu-container">
						<Text className="social-media-text">Social Media</Text>
						<Space direction="horizontal" size={15}>
							<a
								href="https://www.linkedin.com/company/lilypadev/"
								target="_blank"
							>
								<Image preview={false} src="/svg/linkedin.svg" />
							</a>
							<a href="https://twitter.com/lilypad_ev" target="_blank">
								<FaSquareXTwitter
									style={{ color: '#fff', fontSize: '1.8rem' }}
								/>
							</a>
							<a
								href="https://www.facebook.com/share/Ee1a2Hoa21MsE6w9/?mibextid=qi2Omg"
								target="_blank"
							>
								<FaFacebook style={{ color: '#fff', fontSize: '1.8rem' }} />
							</a>
							<a href="https://www.instagram.com/lilypad_ev/" target="_blank">
								<Image preview={false} src="/svg/instagram.svg" />
							</a>
						</Space>
					</Col>
				</Row>
			</Col>
			<Col
				xs={24}
				lg={5}
				className="footer-menu-container footer-legel-terms-responsive-menu"
				style={{ marginBottom: '10px' }}
			>
				<Space direction="horizontal" size={10}>
					<Text
						className="footer-menu-text"
						onClick={() => navigate('/terms-and-condition')}
					>
						Terms & Conditions
					</Text>
					<Divider type="vertical" style={{ backgroundColor: '#fff' }} />
					<Text
						className="footer-menu-text"
						onClick={() => navigate('/privacy-policy')}
					>
						Privacy Policy
					</Text>
				</Space>
			</Col>
			<Col span={24} className="footer-bottom">
				<Text className="footer-bottom-text">
					© {new Date().getFullYear()} LilyPad. All rights reserved.
				</Text>
			</Col>
		</Row>
	);
};

export default Footer;
