import axios from 'axios';

const productApiService = axios.create({
  baseURL: process.env.REACT_APP_API_PRODUCT_BASE_URL,
});

export const javaProductApiService = axios.create({
  baseURL: process.env.REACT_APP_LILYPAD_PRODUCT_SEARCH_BASE_API_URL
});

// Request interceptor to include auth token in specific requests
productApiService.interceptors.request.use(
	(config) => {
		let authToken = localStorage.getItem('auth_token');

		if (authToken) {
			// Check if the request URL requires authorization (e.g., cart-related endpoints)
			if (config.url.includes('cart') || config.url.includes('review')) {
				config.headers['Authorization'] = `Bearer ${authToken}`;
			}
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);


export default productApiService;
