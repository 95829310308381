import React, { useEffect, useState } from "react";
import './userStyle/style.css'
import {
  Layout,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  Typography,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { updateUserDetails, userLogin } from "../../api/user";
import dayjs from "dayjs";
import { setAuthToken } from "../../Redux/actionTypes/authAction";
import { useDispatch } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;
const { Option } = Select;

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userDetails, setUserDetails] = useState(undefined);

  const parseDate = (dateString) => {
    if (!dateString) return null;
    return dayjs(dateString, "DD-MM-YYYY"); // Adjust the date format as per your userDetails.dob format
  };
  const onFinish = (values) => {
    // Format date to string before sending to API
    const formattedValues = {
      email: values.email,
      mobile: values.phoneno,
      gender: values.gender,
      dob: values.dob ? values.dob.format("DD-MM-YYYY") : "", // Format moment date as string
      full_name: values.name,
    };
    // Handle form submission logic here
    // Send 'formattedValues' to your API
    updateUserDetails(formattedValues, userDetails.id)
      .then((response) => {
        // navigate(0)
        getUserInfo();
        message.success("Profile information updated!");
      })
      .catch((error) => {
        message.error("Failed to update profile information!");
      });
  };
  useEffect(() => {
    if (userDetails) {
      form.setFieldsValue({
        name: userDetails.full_name,
        gender: userDetails.gender,
        phoneno: userDetails?.mobile,
        dob: userDetails.dob ? parseDate(userDetails.dob) : null,
        email: userDetails.email,
      });
    } else {
      form.resetFields();
    }
  }, [form, userDetails]);
  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );

    // if (!otpless_user_token) {
    //   navigate("/eStore");
    // } else {
    const userDetails = JSON.parse(localStorage.getItem("user_details"));
    if (userDetails) {
      setUserDetails(userDetails);
    }
    // }
  };

  const getUserInfo = async () => {
    try {
      const otplessUserToken = JSON.parse(
        localStorage.getItem("otpless_user_token")
      );
      const userData = await userLogin(otplessUserToken);
      if (userData) {
        const { auth_token, ...userInfo } = userData.data.data;
        localStorage.setItem("auth_token", auth_token);
        localStorage.setItem("user_details", JSON.stringify(userInfo));
        dispatch(setAuthToken(auth_token));
        dispatch(setUserDetails(userInfo));
        setUserDetails(JSON.parse(localStorage.getItem("user_details")));
      }
    } catch (error) {
      // console.error("failed to fetch user info", error);
    }
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  return (
    <Layout className="layout-section">
      <Title className="title-text">
        My Profile
      </Title>
      <Text className="title-help-text">
        Your profile preferences help us personalize recommendations for you.
      </Text>

      <Form
        form={form}
        name="profile_form"
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={32}>
          <Col span={12} sm={24} xs={24} md={12}>
            <Form.Item name="name"
              rules={[{
                pattern: "^[a-zA-Z ]+$",
                message: "please enter only alphabets",
              }]}>
              <Input className="address-form-inputs" placeholder="Your Name" />
            </Form.Item>
          </Col>
          <Col span={12} sm={24} xs={24} md={12}>
            <Form.Item name="gender">
              <Select placeholder="Select Gender" className="profile-page-select address-form-select" suffixIcon={<CaretDownOutlined className="address-form-select-arrow" />}>
                <Option value="Male" className="select-option">Male</Option>
                <Option value="Female" className="select-option">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12} sm={24} xs={24} md={12}>
            <Form.Item
              name="phoneno"
              rules={[
                { required: false },
                {
                  pattern: "^([0-9]{12})$",
                  message: "please enter correct phone number",
                }
                // {
                //   validator: (value) => {
                //     const regex = /^[0-9]*$/; // Regular expression to match only numbers
                //     if (!value || regex.test(value)) {
                //       return Promise.resolve(); // Pass the validation
                //     } else {
                //       return Promise.reject(); // Fail the validation
                //     }
                //   },
                //   message: "Please enter a valid number!",
                // },
              ]}
            >
              <Input
                placeholder="Please add 91 as prefix for mobile number"
                onKeyDown={(e) => {
                  if (
                    !(
                      e.key === "Backspace" ||
                      e.key === "Delete" ||
                      (e.key >= "0" && e.key <= "9")
                    )
                  ) {
                    e.preventDefault(); // Prevent default action if the key pressed is not a number
                  }
                }}
                className="address-form-inputs"
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={24} xs={24} md={12}>
            <Form.Item name="dob">
              <DatePicker
                placeholder="Birthdate"
                className="address-form-inputs"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12} sm={24} xs={24} md={12}>
            <Form.Item name="email"
              rules={[
                {
                  pattern: "^([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$",
                  message: "please enter correct email",
                }
              ]}
            >
              <Input
                placeholder="Email"
                type="email"
                className="address-form-inputs"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ paddingTop: "20px" }}>
          {/* <Button
            type="primary"
            style={{
              backgroundColor: "white",
              color: "#04b2a9",
              border: "1px solid #04b2a9",
              height: "50px",
              width: "149px",
            }}
          >
            Discard
          </Button> */}
          <Button
            type="default"
            htmlType="submit"
            className="fill-btn"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default ProfilePage;
