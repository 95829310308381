import React from 'react'
import './style.css'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

const BreakOverview = ({ productData }) => {
    return (
        <>
            <table className='overview-table'>
                <tr>
                    <td>&#9679; Frame Type</td>
                    <td>{productData.frameType ? productData.frameType : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Front Suspensions</td>
                    <td>{productData.frontSuspensions ? productData.frontSuspensions : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Rear Suspensions</td>
                    <td>{productData.rearSuspensions ? productData.rearSuspensions : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Front Brake type</td>
                    <td>{productData.frontBrakeType ? productData.frontBrakeType : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Rear Brake type</td>
                    <td>{productData.rearBrakeType ? productData.rearBrakeType : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; ABS / CBS</td>
                    <td>{productData.absCbs ? productData.absCbs : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Wheel Type</td>
                    <td>{productData.wheelType ? productData.wheelType : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Front Tyre</td>
                    <td>{productData.frontTyre ? productData.frontTyre : "-"}</td>
                </tr>
                <tr>
                    <td>&#9679; Rear Tyre</td>
                    <td>{productData.rearTyre ? productData.rearTyre : "-"}</td>
                </tr>
            </table>
        </>
    )
}

export default BreakOverview