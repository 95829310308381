import axios from 'axios';

const authApiService = axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_BASE_URL,
});

// Request interceptor to include auth token in specific requests
authApiService.interceptors.request.use(
	(config) => {
		let authToken = localStorage.getItem('auth_token');
		if (authToken) {
			// Check if the request URL requires authorization (e.g., cart-related endpoints)
			if (config.url.includes('/cart/') || config.url.includes('/auth')) {
				config.headers['Authorization'] = `Bearer ${authToken}`;
			}
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default authApiService;
