import React from 'react';
import { Col, Row, Typography, Image } from 'antd';
//@ts-ignore
import vector11 from '../../assets/images/Vector11.svg';
import './aboutStyle/thirdSection.css';

const { Text, Paragraph } = Typography;

const ThirdSection = () => {
	return (
		<Row className="about-third-section-container">
			<Col span={10} className="about-third-left-container">
				<Image
					src="./../images/scooty blue.png"
					className="vector11"
					preview={false}
				/>
			</Col>
			<Col xs={24} md={12} lg={14} className="about-third-right-container">
				<Paragraph className="about-third-title">Our Vision:</Paragraph>
				<Paragraph className="about-third-description">
					Lilypad envisions a future where electric vehicles are the default
					choice for transportation in India and beyond.
				</Paragraph>

				<Paragraph className="about-third-description">
					By fostering a community of informed consumers and providing an
					unparalleled marketplace experience, we want to contribute in reducing
					carbon footprints. Together, we’re not just choosing a smarter way to
					travel. We’re paving the path towards a clean, green, and more
					sustainable world for generations to come.
				</Paragraph>
			</Col>
		</Row>
	);
};

export default ThirdSection;
