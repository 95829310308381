import {
	LogoutOutlined,
	SettingOutlined,
	ShoppingCartOutlined,
	UserOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import user from '../../../src/assets/images/user.svg';
import locationPin from '../../../src/assets/images/locationPin.svg';
import buy from '../../../src/assets/images/buy.svg';
import setting from '../../../src/assets/images/setting.svg';
import { Button, Image, Menu, Modal, Spin, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveSection } from '../../Redux/actionTypes';
import { userLogout } from '../../api/user';

function Sidebar() {
	const location = useLocation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const [selectedKeys, setSelectedKeys] = useState('/');
	const menuItems = [
		{
			label: 'Profile',
			key: '/user/profile',
			// icon: <Image src={user} className="menu-icon" preview={false} />,
			icon: (
				<svg
					width="22"
					height="22"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
						fill="#848397"
					/>
				</svg>
			),
		},
		{
			label: 'Orders',
			key: '/user/orders',
			// icon: <Image src={buy} className="menu-icon" preview={false} />,
			icon: (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12.1213 9.23312H14.8891C15.3088 9.23312 15.6386 8.88615 15.6386 8.46774C15.6386 8.03912 15.3088 7.70236 14.8891 7.70236H12.1213C11.7016 7.70236 11.3719 8.03912 11.3719 8.46774C11.3719 8.88615 11.7016 9.23312 12.1213 9.23312ZM18.1766 3.92749C18.7861 3.92749 19.1858 4.1418 19.5855 4.61123C19.9852 5.08067 20.0551 5.7542 19.9652 6.36549L19.0159 13.06C18.8361 14.3469 17.7569 15.2949 16.4879 15.2949H5.58639C4.25742 15.2949 3.15828 14.255 3.04837 12.908L2.12908 1.7834L0.620259 1.51807C0.22057 1.44664 -0.0592117 1.04864 0.0107338 0.640433C0.0806793 0.223045 0.470376 -0.0535127 0.880056 0.0087383L3.2632 0.375101C3.60293 0.437352 3.85274 0.722074 3.88272 1.06905L4.07257 3.35499C4.10254 3.68257 4.36234 3.92749 4.68209 3.92749H18.1766ZM5.42631 16.9079C4.58697 16.9079 3.9075 17.6018 3.9075 18.459C3.9075 19.3061 4.58697 20 5.42631 20C6.25567 20 6.93514 19.3061 6.93514 18.459C6.93514 17.6018 6.25567 16.9079 5.42631 16.9079ZM16.6676 16.9079C15.8282 16.9079 15.1487 17.6018 15.1487 18.459C15.1487 19.3061 15.8282 20 16.6676 20C17.4969 20 18.1764 19.3061 18.1764 18.459C18.1764 17.6018 17.4969 16.9079 16.6676 16.9079Z"
						fill="#848397"
					/>
				</svg>
			),
		},
		{
			label: 'Address',
			key: '/user/address',
			// icon: <Image src={locationPin} className="menu-icon" preview={false} />,
			icon: (
				<svg
					width="20"
					height="20"
					viewBox="0 0 18 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0.5 8.31776C0.5 3.71789 4.34388 0 8.99345 0C13.6561 0 17.5 3.71789 17.5 8.31776C17.5 10.6357 16.657 12.7876 15.2695 14.6116C13.7388 16.6235 11.8522 18.3765 9.72854 19.7524C9.24251 20.0704 8.80387 20.0944 8.27045 19.7524C6.13474 18.3765 4.24809 16.6235 2.7305 14.6116C1.34198 12.7876 0.5 10.6357 0.5 8.31776ZM6.19423 8.57675C6.19423 10.1177 7.45166 11.3297 8.99345 11.3297C10.5362 11.3297 11.8058 10.1177 11.8058 8.57675C11.8058 7.0478 10.5362 5.77683 8.99345 5.77683C7.45166 5.77683 6.19423 7.0478 6.19423 8.57675Z"
						fill="#848397"
					/>
				</svg>
			),
		},
		{
			label: 'Logout',
			key: 'logout',
			// icon: <Image src={setting} className="menu-icon" preview={false} />,
			icon: (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M18.4023 13.3797C18.76 13.5875 19.036 13.9156 19.2301 14.2436C19.6083 14.9216 19.5776 15.7527 19.2097 16.4853L18.4943 17.7976C18.1162 18.4974 17.411 18.9348 16.6855 18.9348C16.3278 18.9348 15.9292 18.8255 15.6022 18.6068C15.3365 18.4209 15.0299 18.3552 14.7029 18.3552C13.6911 18.3552 12.8429 19.2629 12.8122 20.3455C12.8122 21.603 11.872 22.5872 10.6968 22.5872H9.30692C8.12145 22.5872 7.18125 21.603 7.18125 20.3455C7.16081 19.2629 6.31259 18.3552 5.30085 18.3552C4.96361 18.3552 4.65702 18.4209 4.40153 18.6068C4.0745 18.8255 3.66572 18.9348 3.31825 18.9348C2.58245 18.9348 1.87729 18.4974 1.49917 17.7976L0.79402 16.4853C0.415896 15.7745 0.395456 14.9216 0.773581 14.2436C0.937094 13.9156 1.24368 13.5875 1.59115 13.3797C1.87729 13.2266 2.06125 12.9751 2.23498 12.6799C2.74596 11.7395 2.43937 10.5038 1.57071 9.94609C0.55897 9.32279 0.231943 7.93402 0.814459 6.85144L1.49917 5.56108C2.09191 4.4785 3.35913 4.09577 4.38109 4.73001C5.27019 5.2549 6.425 4.90497 6.9462 3.97548C7.10972 3.6693 7.20169 3.34124 7.18125 3.01319C7.16081 2.58671 7.27323 2.18211 7.4674 1.85406C7.84553 1.17607 8.53024 0.738667 9.27627 0.716797H10.7172C11.4735 0.716797 12.1582 1.17607 12.5363 1.85406C12.7203 2.18211 12.8429 2.58671 12.8122 3.01319C12.7918 3.34124 12.8838 3.6693 13.0473 3.97548C13.5685 4.90497 14.7233 5.2549 15.6226 4.73001C16.6344 4.09577 17.9118 4.4785 18.4943 5.56108L19.179 6.85144C19.7718 7.93402 19.4447 9.32279 18.4228 9.94609C17.5541 10.5038 17.2475 11.7395 17.7687 12.6799C17.9322 12.9751 18.1162 13.2266 18.4023 13.3797ZM7.10972 11.6629C7.10972 13.3797 8.4076 14.7466 10.0121 14.7466C11.6165 14.7466 12.8838 13.3797 12.8838 11.6629C12.8838 9.94609 11.6165 8.56826 10.0121 8.56826C8.4076 8.56826 7.10972 9.94609 7.10972 11.6629Z"
						fill="#848397"
					/>
				</svg>
			),
		},
	];

	useEffect(() => {
		const pathName = location.pathname;
		setSelectedKeys(pathName);
		dispatch(setActiveSection(pathName.slice(1)));
	}, [location.pathname]);

	const navigate = useNavigate();

	const logoutUser = async () => {
		setIsLoading(true);
		try {
			const logout = await userLogout();

			if (logout) {
				window.localStorage.removeItem('auth_token');
				window.localStorage.removeItem('otpless_user_token');
				window.localStorage.removeItem('user_details');
				setIsLoading(false);
				navigate('/');
				navigate(0);
				setIsModalOpen(false);
			}
		} catch (error) {
			setIsLoading(false);
			message.error('Failed to logout!');
			setIsModalOpen(false);
		}
	};
	return (
		<div className="SideMenu">
			<Modal
				centered
				onCancel={() => setIsModalOpen(false)}
				title={[
					<p style={{ fontFamily: 'AvenirNextLTPro' }}>
						<WarningOutlined
							style={{
								color: '#FFCC00',
								marginRight: '10px',
								fontSize: '20px',
							}}
						/>{' '}
						Are you sure you want to logout?
					</p>,
				]}
				open={isModalOpen}
				footer={[
					<Button
						disabled={isLoading}
						className="fill-btn"
						style={{ height: '35px', width: '75px', borderRadius: '4px' }}
						onClick={logoutUser}
					>
						{isLoading ? <Spin /> : 'Yes'}
					</Button>,
					<Button
						disabled={isLoading}
						className="outline-btn"
						style={{ height: '35px', width: '70px', borderRadius: '4px' }}
						onClick={() => setIsModalOpen(false)}
					>
						{isLoading ? <Spin /> : 'No'}
					</Button>,
				]}
			>
				<Typography style={{ fontFamily: 'AvenirNextLTPro' }}>
					Are you sure you want to logout?
				</Typography>
			</Modal>
			<Menu
				className="sideMenuVertical"
				onClick={(item) => {
					if (item.key === 'logout') {
						setIsModalOpen(true);
					} else {
						navigate(item.key);
					}
				}}
				selectedKeys={[selectedKeys]}
				defaultOpenKeys={['/catalog']}
				mode="inline"
				items={menuItems}
			></Menu>
		</div>
	);
}
export default Sidebar;
