export const formatToRupees = (value) => {
	if (typeof value !== 'number') {
		value = Number(value);
	}
	const isNegative = value < 0;
	value = Math.abs(value);

	const [integerPart, decimalPart] = value.toString().split('.');
	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		','
	);
	const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '';
	const formattedValue = `${
		isNegative ? '-' : ''
	}${formattedIntegerPart}${formattedDecimalPart}`;
	return formattedValue;
};
