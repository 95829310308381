import React, { useState } from 'react'
import './style.css'
import { Collapse, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons';
import Overview from './Overview';
import Performance from './Performance';
import Dimensions from './Dimensions';
import BreakOverview from './BreakOverview';
import ProductFeatures from './ProductFeatures';
const { Text } = Typography;

const panelStyle = {
    marginBottom: 10,
    background: '#000000',
    borderRadius: '0px',
    border: '1px solid #000',
    fontFamily: 'AvenirNextLTPro',
    padding: '5px'
};

export const Description = ({ productData }) => {
    const [activeKey, setActiveKey] = useState([]);

    const onChange = (key) => {
        setActiveKey([key]);
    };

    const getItems = (panelStyle) => [
        {
            key: '1',
            label: 'Overview',
            children: <Overview productData={productData} />,
            style: panelStyle,
        },
        {
            key: '2',
            label: 'Performance',
            children: <Performance productData={productData} />,
            style: panelStyle,
        },
        {
            key: '3',
            label: 'Dimension',
            children: <Dimensions productData={productData} />,
            style: panelStyle,
        },
        {
            key: '4',
            label: 'Brakes, Wheels and Suspensions',
            children: <BreakOverview productData={productData} />,
            style: panelStyle,
        },
        {
            key: '5',
            label: 'Product Features',
            children: <ProductFeatures productData={productData} />,
            style: panelStyle,
        },
    ];

    return (
        <>
            <Text className='product-description-text'>
                {productData?.productDescription}
                {/* A revolutionary technological Electric Scooter with {productData.motorPower} motor. The plank is lighter, more durable, robust, and more nimble. For effortless storage and transit when not in use, the electric scooter folds down in just a few quick clicks. These motors have internal planetary gears and are a BLDC type. The scooters are built of carbon steel or aluminum, and some of the features include
                LED lights. */}
            </Text>
            <br />
            <br />
            <Collapse
                activeKey={activeKey}
                onChange={onChange}
                items={getItems(panelStyle)}
                bordered={false}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                expandIcon={({ isActive }) => <RightOutlined className={isActive ? 'accordion-icon-active' : 'accordion-icon'} rotate={isActive ? 90 : 0} />}
                style={{
                    background: '#000000',
                    color: "#ffff"
                }}
                accordion
            />
        </>
    )
}
