import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import './Filters.css';
import { useSelector } from 'react-redux';

const ColorFilter = ({ onColorChange, isResetFilter }) => {
	// const productState = useSelector((state) => (state ? state.product : []));
	// const colorOptions = productState.colors
	const [selectedColors, setSelectedColors] = useState([]);

	const colorOptions = [
		{ label: 'White', value: 'White' },
		{ label: 'Black', value: 'Black' },
		{ label: 'Gray', value: 'Gray' },
		{ label: 'Silver', value: 'Silver' },
		{ label: 'Blue', value: 'Blue' },
		{ label: 'Red', value: 'Red' },
		{ label: 'Brown', value: 'Brown' },
		{ label: 'Green', value: 'Green' },
		{ label: 'Orange', value: 'Orange' },
		{ label: 'Beige', value: 'Beige' },
		{ label: 'Purple', value: 'Purple' },
		{ label: 'Pink', value: 'Pink' },
		{ label: 'Gold', value: 'Gold' },
		{ label: 'Yellow', value: 'Yellow' },
		{ label: 'Violet', value: 'Violet' },
	];

	const handleColorChange = (selected) => {
		setSelectedColors(selected);

		// Emit selected values to the parent component
		if (onColorChange) {
			onColorChange(selected.map((element) => element.toLowerCase()));
		}
	};

	useEffect(() => {
		if (isResetFilter) {
			setSelectedColors([]);
		}
	}, [isResetFilter]);

	return (
		<div style={{ marginBottom: '16px' }}>
			<h4 className="filter-header">Color</h4>
			<Checkbox.Group
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					marginTop: '10px',
					fontSize: '15px',
					fontWeight: '700',
					color: '#000',
				}}
				className="custom-checkbox"
				options={colorOptions}
				value={selectedColors}
				onChange={handleColorChange}
			/>
		</div>
	);
};
export default ColorFilter;
