import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import './Filters.css';

const BrandFilter = ({ onBrandChange, brands, isResetFilter }) => {
	const brandOptions = [
		'Ninebot',
		'Razor',
		'Apollo',
		'FluidFreeRide',
		'Dualtron',
	];

	const [selectedBrands, setSelectedBrands] = useState([]);

	const handleBrandChange = (selected) => {
		const brandLabels = [];
		brands.forEach((brand) => {
			if (selected.includes(brand.value)) {
				brandLabels.push(brand.label);
			}
		});
		setSelectedBrands(selected);

		// Emit selected values to the parent component
		if (onBrandChange) {
			onBrandChange(selected, brandLabels);
		}
	};

	useEffect(() => {
		if (isResetFilter) {
			setSelectedBrands([]);
		}
	}, [isResetFilter]);

	return (
		<div style={{ marginBottom: '16px' }}>
			<h4 className="filter-header">Brands</h4>
			<div style={{}}>
				<Checkbox.Group
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						marginTop: '10px',
					}}
					className="custom-checkbox"
					options={brands}
					value={selectedBrands}
					onChange={handleBrandChange}
				/>
			</div>
		</div>
	);
};

export default BrandFilter;
