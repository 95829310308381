// authReducer.js - Redux reducer for authentication
const initialState = {
	authToken: null,
	userDetails: {},
	userCoords: undefined,
	userPincode: undefined,
	locationEnabled: undefined,
	userPincodeEdited: undefined,
	addresses: [],
	// other relevant authentication status
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_IS_LOCATION_ENABLED':
			return {
				...state,
				locationEnabled: action.payload,
			};
		case 'SET_AUTH_TOKEN':
			return {
				...state,
				authToken: action.payload,
			};
		case 'SET_USER_DETAILS':
			return {
				...state,
				userDetails: action.payload,
			};

		case 'GET_USER_COORDS_SUCCESS':
			return {
				...state,
				userCoords: action.payload,
			};

		case 'GET_USER_PINCODE_SUCCESS':
			return {
				...state,
				userPincode: action.payload,
			};

		case 'GET_USER_PINCODE_SUCCESS_Edited':
			return {
				...state,
				userPincodeEdited: action.payload,
			};

		case 'SET_ADDRESS':
			return {
				...state,
				addresses: action.payload,
			};
		case 'SET_ADDED_ADDRESS':
			return {
				...state,
				addresses: [...state.addresses, action.payload],
			};
		case 'SET_EDITED_ADDRESS':
			return {
				...state,
				addresses: state.addresses.map((address) =>
					address.id === action.payload.id ? action.payload : address
				),
			};
		case 'SET_DELETED_ADDRESS':
			const updatedAddresses = [...state.addresses];
			const indexToDelete = updatedAddresses.findIndex(
				(address) => address.id === action.payload.id
			);
			if (indexToDelete !== -1) {
				updatedAddresses.splice(indexToDelete, 1);
			}
			return {
				...state,
				addresses: updatedAddresses,
			};
		// other cases for authentication status handling
		default:
			return state;
	}
};

export default authReducer;
