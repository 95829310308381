import React, { useEffect, useState } from "react";
import { Row, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import OrderSummary from "../Components/CheckOut/OrderSummary";
import UserAddressCard from "../Components/CheckOut/UserAddressCard";
import { deleteAddress, getUserAddress } from "../api/user";
import { useNavigate } from "react-router-dom";
import { setDeletedAddress } from "../Redux/actionTypes/authAction";

const UserAddressSection = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const addressState = useSelector((state) => (state ? state.auth.addresses : []));
  const [addressList, setAddressList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch()
  const { Text } = Typography;

  const [form] = useForm();
  const navigate = useNavigate();

  form.setFieldsValue({
    email: userDetails?.email,
  });
  form.setFieldsValue({
    mobile: userDetails?.mobile,
  });

  const getAndStoreUserAddress = async () => {
    if (addressState.length > 0) {
      setAddressList(addressState);
    }
    else {
      const addressList = await getUserAddress();
      setAddressList(addressList);
    }
  };

  const checkUserLoggedIn = () => {
    const otpless_user_token = JSON.parse(
      localStorage.getItem("otpless_user_token")
    );
    if (!otpless_user_token) {
      navigate("/products");
    }
  };

  const deleteAddressFromMenu = (addressObj) => {
    deleteAddress(addressObj).then((response) => {
      dispatch(setDeletedAddress(response))
    });
  }

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    getAndStoreUserAddress();
  }, [addressState]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleReload = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("reload", handleReload);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("reload", handleReload);
    };
  }, [windowWidth]);

  const getOrderTotalAmount = (amount) => {
    // don't delete this function
  }

  return (
    <>

      <div
        onClick={() => navigate(-1)}
        style={{ margin: '20px', display: 'flex', width: '140px' }}
      >
        <Text className="back-btn-text" style={{ height: '40px' }}>
          {' '}
          <LeftOutlined className="product-detail-back-btn-icon" />
          Back
        </Text>
      </div>
      <Row gutter={16} type="flex" justify="center" style={{ margin: 0 }}>

        <UserAddressCard
          addressList={addressList}
          windowWidth={windowWidth}
          onEdit={(address) => {
            navigate(`/checkout/shipping?addressId=${address.id}`)
          }}
          onDelete={(address) => deleteAddressFromMenu({ 'id': address.id })}
        />
        {windowWidth > 500 && (
          <OrderSummary getOrderTotalAmount={getOrderTotalAmount} />
        )}
      </Row>
    </>
  );
};

export default UserAddressSection;
