import {
  Row,
  Col,
  Typography,
  Image,
  Input,
  Form,
  Button,
  message,
  Divider,
  Space,
  Card,
  Radio,
  Upload,
  Flex,
} from "antd";
import "../../src/Components/ProductUpload/styles/style.css";
import logo from "./../assets/Combination Mark PNG-02.png";
import authApiService from "../api/authApiService";
import { useForm } from "antd/es/form/Form";
import { useRef, useState } from "react";
import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { utils, writeFile, read } from "xlsx";
import { kycLogin } from "../api/user";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const VendorRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isManual, setIsManual] = useState(null);
  const [jsonData, setJsonData] = useState("");
  const [vendorDocs, setVendorDocs] = useState([]);
  const [documentUploadError, setDocumentUploadError] = useState("")
  const [dealerError, setDealerError] = useState("")
  const [form] = useForm();
  // let vendorDocs = [];

  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const validatePassword = (_, value) => {
    const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/);

    if (!passwordRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least 1 lowercase, 1 uppercase, 1 number, and 1 special character."
      );
    }

    return Promise.resolve();
  };

  function parseNumericalFields(obj) {
    const numericalFields = [
      "itemWeight",
      "size",
      "sellingPrice",
      "productPrice",
      "discount",
      "productQuantity",
      "radius",
      "pincode",
    ];

    for (const field of numericalFields) {
      if (obj[field]) {
        obj[field] = parseFloat(obj[field]);
      }
    }

    return obj;
  }

  const RegisterVendor = async (body) => {
    setIsLoading(true);
    if (body.dealers === undefined && jsonData === "") {
      setDealerError("Please upload excel file.")
      setIsLoading(false);
      return
    }
    if (vendorDocs.length === 0) {
      setDocumentUploadError("Please upload valid documents.");
      setIsLoading(false);
      return;
    }
    if (jsonData !== "") {
      jsonData.map((e) => {
        if (e["radius"] === undefined) {
          e["radius"] = 50;
        }
        e.location = {
          pincode: e.pincode,
          radius: e.radius,
        };
      });
      body.dealers = jsonData;
    } else {
      let parsedDealers = body.dealers?.map((item) =>
        parseNumericalFields(item)
      );
      body.dealers = parsedDealers;
      body.dealers = body.dealers?.map((item) => {
        let modifiedvalue = {
          ...item,
          location: { pincode: item.pincode, radius: item.radius },
        };

        return modifiedvalue;
      });
    }
    body.vendorDocs = vendorDocs.map((e) => e.url);
    body.textMatchPayload = {
      textMatchKeys: ["name", "address"],
      name: {
        value1: body.full_name,
        value2: "",
      },
      address: {
        value1: body.registeredAddress,
        value2: "",
      },
    };

    delete body.confirmPassword;
    try {
      const { data } = await authApiService.post("/auth/register-vendor", body);
      if (data) {
        const vendorID = data?.vendorResponse?.id;
        // Commented dated :- 16th April 2024 Reason: We don't need face recognization feature for vendor registration
        // starOnboarding(vendorID);
        form.resetFields();
        message.success("Vendor registered successfully!");
        setIsManual(null)
        setVendorDocs([])
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message);
      } else {
        message.error("Failed to register as a vendor!");
      }
    }
  };
  const { Title, Text } = Typography;
  const { TextArea } = Input;

  const onChange = (e) => {
    setIsManual(e.target.value);
  };

  const customRequest = async ({ filename, file, onSuccess, onError }) => {
    if (filename === "dealer") {
      if (!file) {
        // console.error("Please select a file.");
        return;
      }
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);
          setJsonData(json);
        };
        reader.readAsBinaryString(file);
        onSuccess(true);
        setDealerError("")
      } catch (error) {
        onError(error);
        message.error("File upload failed.");
      }
    } else {
      if (!file) {
        // console.error("Please select a file.");
        return;
      }
      // const bucketName = process.env.REACT_APP_AWS_S3_PRODUCT_IMAGE_BUCKET;
      // const bucketRegion = process.env.REACT_APP_AWS_S3_BUCKET_REGION;
      // const awsAccessKey =
      //   process.env.REACT_APP_AWS_LILYPAD_PRODUCTS_ACCESS_KEY;
      // const awsSecretKey =
      //   process.env.REACT_APP_AWS_LILYPAD_PRODUCTS_SECRET_KEY;
      // const s3Client = new S3Client({
      //   region: bucketRegion,
      //   credentials: {
      //     accessKeyId: awsAccessKey,
      //     secretAccessKey: awsSecretKey,
      //   },
      // });
      // Set the S3 parameters
      // const params = {
      //   Bucket: bucketName,
      //   Key: file.name,
      //   Body: file,
      //   ContentType: file.type,
      // };
      try {
        // const command = new PutObjectCommand(params);
        // const response = await s3Client.send(command);
        // onSuccess({ url: response.Location });
        // message.success(`${file.name} uploaded successfully.`);
        // let bucketBaseUrl = process.env.REACT_APP_AWS_BUCKET_BASE_URL;
        // let fileUrl = `${bucketBaseUrl}/${encodeURIComponent(file.name)}`;
        setVendorDocs([...vendorDocs, { uid: file.uid, url: 'product.pdf' }]);
        setDocumentUploadError("")
        return 'product.pdf';
      } catch (error) {
        onError(error);
        message.error("File upload failed.");
      }
    }
  };

  const handleDownload = () => {
    const data = [
      [
        "name",
        "pincode",
        "radius",
        "address1",
        "address2",
        "demoType",
        "deliveryType",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "HOME_DEMO_ONLY/DEALER_DEMO_ONLY",
        "DEALER_REGION_ONLY/ALL_LOCATIONS/NO_DELIVERY",
      ],
    ];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(data);

    utils.book_append_sheet(wb, ws, "sheet1");
    writeFile(wb, "SampleExcel.xlsx", { compression: true });
  };

  const props = {
    beforeUpload: (file) => {
      const allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      const isAllowedFileType = allowedFileTypes.includes(file.type);

      if (!isAllowedFileType) {
        message.error("Please upload file in xlsx format.");
        return Upload.LIST_IGNORE;
      }

      return true;
    },

    name: "dealer",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest,
  };

  const docProps = {
    beforeUpload: (file) => {
      const allowedFileTypes = [
        "application/pdf"
      ];
      const isAllowedFileType = allowedFileTypes.includes(file.type);

      if (!isAllowedFileType) {
        message.error("Please upload file in pdf format.");
        return Upload.LIST_IGNORE;
      }

      return true;
    },

    name: "documents",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest,
  };

  const starOnboarding = async (vendorID) => {
    const kycLoginResponse = await kycLogin();
    if (kycLoginResponse) {
      const accessToken = kycLoginResponse?.response?.result?.token;
      const workflowId = process.env.REACT_APP_WORKFLOW_ID;
      const transactionId = vendorID;
      const hyperKycConfig = new window.HyperKycConfig(
        accessToken,
        workflowId,
        transactionId
      );
      window.HyperKYCModule.launch(hyperKycConfig, handler);
    }
  };

  const handler = (HyperKycResult) => {
    if (HyperKycResult.Cancelled) {
      // console.info(HyperKycResult.Cancelled);
    } else if (HyperKycResult.Failure) {
      // console.info(HyperKycResult.Failure);
    } else if (HyperKycResult.Success) {
      // console.info(HyperKycResult.Success);
    }
  };

  const onDealerDocRemove = (file) => {
    message.success(`${file.name} removed from the list`);
    setJsonData("")
  }

  const onVendorDocRemove = (file) => {
    setVendorDocs((prevList) => {
      const newUrls = prevList.filter((item) => item.uid !== file.uid);
      message.success(`${file.name} removed from the list`);
      return newUrls;
    });
  }

  return (
    <>
      <Row className="first-section" justify="center" align="top">
        <Col
          className="first-section-col"
          span={24}
          style={{ textAlign: "center" }}
        >
          <Image
            preview={false}
            src={logo}
            className="nav-logo"
            style={{ alignContent: "center", maxWidth: "200px", objectFit: "contain" }}
          />
          <Title level={2} className="heading" style={{ textAlign: "center", color: "#00ffff" }}>
            Vendor Registration
          </Title>
        </Col>
      </Row>
      <Form
        onFinish={RegisterVendor}
        form={form}
        name="profile_form"
        layout="vertical"
      >
        <Row align={"top"} justify={"center"}>
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title style={{ color: "#00ffff" }} level={2}>Vendor Information</Title>
          </Col>
          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>CIN *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter CIN number!" },
                    {
                      pattern: "^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$",
                      message: "please enter correct CIN number",
                    }
                  ]}
                  name="cin"
                >
                  <Input
                    placeholder="Enter your CIN number"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>GST IN *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter GSTIN number!" },
                    {
                      pattern: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                      message: "please enter correct GSTIN number",
                    }
                  ]}
                  name="gstin"
                >
                  <Input
                    placeholder="Enter your GST IN"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>PAN *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter PAN number!" },
                    {
                      pattern: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                      message: "please enter correct PAN number",
                    }
                  ]}
                  name="pan"
                >
                  <Input
                    placeholder="Enter your PAN number"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Registered Address *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter your address" },
                  ]}
                  name="registeredAddress"
                >
                  <TextArea
                    rows={4}
                    placeholder="Write your Registered Address here"
                    maxLength={500}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Name of Contact person  *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter name!" },
                    {
                      pattern: "^[a-zA-Z ]+$",
                      message: "please enter only alphabets",
                    }
                  ]}
                  name="full_name"
                >
                  <Input
                    placeholder="Enter the name of contact person"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Email of Contact person  *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter email!" },
                    {
                      pattern: "^([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$",
                      message: "please enter correct email",
                    }
                  ]}
                  name="email"
                >
                  <Input
                    type="email"
                    placeholder="Enter Email"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Phone of Contact person  *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter phone number!" },
                    {
                      pattern: "^[0-9]{12}$",
                      message: "please enter correct phone number",
                    }
                  ]}
                  name="mobile"
                >
                  <Input
                    placeholder="Please add 91 as prefix for mobile number"
                    type="number"
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Brand name *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter brand name!" },
                  ]}
                  name="brandName"
                >
                  <Input
                    placeholder="Enter your brand name"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Radio.Group
                  onChange={onChange}
                  value={isManual}
                  style={{ marginBottom: "20px" }}
                >
                  <Radio value={1}>Upload Excel</Radio>
                  <Radio value={2}>Upload Manually</Radio>
                </Radio.Group>
                {isManual === 1 ? (
                  <>
                    <br />
                    <Form.Item
                      name="dealerFile"
                      rules={[
                        { required: true, message: "Please upload excel file!" },
                      ]}
                    >
                      <Flex gap="small" wrap="wrap">
                        <Upload {...props} onRemove={onDealerDocRemove}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                        <Button
                          onClick={handleDownload}
                          icon={<DownloadOutlined />}
                        >
                          Download Sample excel
                        </Button>
                      </Flex>
                      {dealerError !== "" && <div><Text style={{ color: 'red', fontSize: 12 }}>{dealerError}</Text></div>}
                    </Form.Item>
                  </>
                ) : isManual === 2 ? (
                  <>
                    <br />
                    <Text style={{ marginBottom: '10px', color: "#fff" }}>Dealers</Text>
                    <Form.List
                      initialValue={[
                        {
                          name: "",
                          pincode: null,
                          address1: "",
                          address2: "",
                          radius: null,
                          demoType: null,
                          deliveryType: null,
                        },
                      ]}
                      name="dealers"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 8,
                              }}
                              align="baseline"
                            >
                              <Card>
                                <Row>
                                  <Col span={12} style={{ padding: "0 10px" }}>
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "name"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing name",
                                        },
                                      ]}
                                    >
                                      <Input className="PU-input" placeholder="Dealer name" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12} style={{ padding: "0 10px" }}>
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "pincode"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing pincode",
                                        },
                                        {
                                          validator: (_, value) => {
                                            if (value < 0) {
                                              return Promise.reject(
                                                "Pincode cannot be negative"
                                              );
                                            } else {
                                              return Promise.resolve();
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="PU-input"
                                        type="number"
                                        ref={inputRef}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Pincode"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    style={{ margin: "10px 10px 0 10px" }}
                                    span={23}
                                  >
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "radius"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing radius",
                                        },
                                        {
                                          validator: (_, value) => {
                                            if (value < 0) {
                                              return Promise.reject(
                                                "Radius cannot be negative"
                                              );
                                            } else {
                                              return Promise.resolve();
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      <Input
                                        type="number"
                                        ref={inputRef}
                                        onKeyDown={handleKeyDown}
                                        className="PU-input"
                                        placeholder="Delivery Radius"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    style={{ margin: "10px 10px" }}
                                    span={23}
                                  >
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "address1"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing address line 1",
                                        },
                                      ]}
                                    >
                                      <TextArea
                                        rows={4}
                                        className="PU-input"
                                        placeholder="Address line 1"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    style={{ margin: "0px 10px 10px 10px" }}
                                    span={23}
                                  >
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "address2"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing address line 2",
                                        },
                                      ]}
                                    >
                                      <TextArea
                                        rows={4}
                                        className="PU-input"
                                        placeholder="Address line 2"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ margin: "0 10px" }}>
                                    <Text

                                      style={{ marginTop: "10px", color: "#fff" }}
                                    >
                                      Select demo type
                                    </Text>
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "demoType"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing demo type",
                                        },
                                      ]}
                                    >
                                      <Radio.Group>
                                        <Radio value="HOME_DEMO_ONLY">
                                          Home demo only
                                        </Radio>
                                        <Radio value="DEALER_DEMO_ONLY">
                                          Dealer demo only
                                        </Radio>
                                        <Radio value="BOTH">Both</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ margin: "10px 10px" }}>
                                    <Text

                                      style={{ marginTop: "10px", color: "#fff" }}
                                    >
                                      Select delivery type
                                    </Text>
                                    <Form.Item
                                      {...restField}
                                      style={{ margin: "0" }}
                                      name={[name, "deliveryType"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing delivery type",
                                        },
                                      ]}
                                    >
                                      <Radio.Group>
                                        <Radio value="DEALER_REGION_ONLY">
                                          Dealer region only
                                        </Radio>
                                        <Radio value="ALL_LOCATIONS">
                                          All locations
                                        </Radio>
                                        <Radio value="NO_DELIVERY">
                                          No delivery
                                        </Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col span={24} sm={24} xs={24} md={12}>
                <Upload {...docProps} onRemove={onVendorDocRemove}>
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Documents
                  </Button>
                </Upload>{" "}
                {documentUploadError !== "" && <div><Text style={{ color: 'red', fontSize: 12 }}>{documentUploadError}</Text></div>}
                <Text style={{ fontSize: 12, color: "#ffff" }}>* Upload documents in pdf format only.</Text>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: "16px 0" }} />
          <Col span={18} sm={24} style={{ maxWidth: "1150px" }}>
            <Title style={{ color: "#00ffff" }} level={2}>Vendor Password</Title>
          </Col>

          <Col span={18}>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Password *</Text>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter a password!" },
                    {
                      validator: validatePassword,
                    },
                  ]}
                  name="password"
                >
                  <Input
                    type="password"
                    placeholder="Enter a password"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24} sm={24} xs={24} md={12}>
                <Text style={{ color: "#fff" }}>Confirm Password *</Text>
                <Form.Item
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The password that you entered does not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  name="confirmPassword"
                >
                  <Input
                    type="password"
                    placeholder="Confirm your password"
                    style={{ height: "56px", color: "#000" }}
                    className="PU-input"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={18} style={{ margin: "20px 0" }}>
            <Button loading={isLoading} htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default VendorRegistration;
