import React, { useEffect, useState } from 'react';
import './style.css';
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Dropdown,
	Empty,
	Menu,
	Row,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

const UserAddressCard = ({ addressList, onEdit, onDelete, windowWidth }) => {
	const navigate = useNavigate();
	const [selectedCard, setSelectedCard] = useState(null);
	const [selectedAddress, setSelectedAddress] = useState(null);

	const handleCardClick = (selectedAddress, index) => {
		setSelectedCard(index);
		setSelectedAddress(selectedAddress);
	};

	const onAddressSelected = () => {
		localStorage.setItem('selectedAddress', JSON.stringify(selectedAddress));
		navigate('/checkout/payment');
	};

	const generateAddressString = (addressObj) => {
		const getValueOrDefault = (obj, key) => (obj && obj[key]) || '';
		const formattedAddress = `${getValueOrDefault(
			addressObj,
			'firstName'
		)} ${getValueOrDefault(addressObj, 'lastName')}\n${getValueOrDefault(
			addressObj,
			'address'
		)}, ${getValueOrDefault(addressObj, 'city')}, ${getValueOrDefault(
			addressObj,
			'state'
		)}, ${getValueOrDefault(addressObj, 'pincode')}\n${getValueOrDefault(
			addressObj,
			'countryRegion'
		)}\nContact: ${getValueOrDefault(addressObj, 'contact')}`;
		return formattedAddress;
	};

	const getAddressFromLocalStorage = () => {
		const storedAddress = localStorage.getItem('selectedAddress');
		if (storedAddress) {
			const selectedAddress = JSON.parse(storedAddress);
			setSelectedAddress(selectedAddress);
			const index = addressList.findIndex((address) => {
				return address.id === selectedAddress.id;
			});
			setSelectedCard(index);
		} else {
			setSelectedCard(0);
		}
	};

	useEffect(() => {
		getAddressFromLocalStorage();
	}, []);

	useEffect(() => {
		const index = addressList.findIndex((address) => {
			return address.id === selectedAddress?.id;
		});
		if (index !== -1) setSelectedCard(index);
	}, [addressList]);

	// Menu for the dropdown with edit and delete options
	const menu = (addressObj) => (
		<Menu style={{ fontFamily: 'AvenirNextLTPro' }}>
			<Menu.Item key="edit" onClick={() => onEdit(addressObj)}>
				Edit
			</Menu.Item>
			<Menu.Item key="delete" onClick={() => onDelete(addressObj)}>
				Delete
			</Menu.Item>
		</Menu>
	);

	return (
		<Col xs={24} sm={24} md={10} className="select-address-card-container">
			{windowWidth > 500 && (
				<Breadcrumb
					className="payment-page-breadcrumb"
					separator=">"
					style={{
						color: '#00ffff',
						fontSize: '20px',
						fontFamily: 'AvenirNextLTPro',
					}}
					items={[
						{
							title: 'Information',
						},
						// {
						//   title: (
						//     <Link to={"/checkout/shipping"}>
						//       <span style={{ cursor: "pointer" }}>Shipping</span>
						//     </Link>
						//   ),
						// },
						{
							title: <span style={{ cursor: 'pointer' }}>Address</span>,
						},
					]}
				/>
			)}
			<div
				style={{
					marginTop: '10px',
					height: '450px',
					overflowY: 'auto',
				}}
			>
				{addressList?.length === 0 ? (
					<Empty
						image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
						imageStyle={{
							height: 60,
							marginTop: '50px',
						}}
						description={
							<span style={{ fontFamily: 'AvenirNextLTPro' }}>
								No addresses available, please add at least one address
							</span>
						}
					/>
				) : (
					addressList.map((address, index) => (
						<Card
							className="address-card"
							key={index}
							onClick={() => handleCardClick(address, index)}
							style={{
								backgroundColor: selectedCard === index ? '#ffff' : '#000',
								color: selectedCard === index ? 'black' : 'white',
								border: selectedCard === index ? '2px solid #00ffff' : 'none',
								transition: 'background-color 0.3s, box-shadow 0.3s',
								margin: '10px 0',
								cursor: 'pointer',
							}}
						>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ marginRight: '20px' }}>
									{' '}
									<p style={{ fontFamily: 'AvenirNextLTPro' }}>
										{address.save_as}
									</p>
								</div>
								<div style={{ marginRight: '20px', flexGrow: 1 }}>
									{' '}
									<p style={{ fontFamily: 'AvenirNextLTPro' }}>
										<b>{generateAddressString(address)}</b>
									</p>
								</div>
								<Dropdown
									overlay={menu(address)}
									placement="bottomRight"
									trigger={['click']}
								>
									<EllipsisOutlined
										style={{ fontSize: 24, cursor: 'pointer' }}
									/>
								</Dropdown>
							</div>
						</Card>
					))
				)}
			</div>
			<div className="address-btns-container">
				<Link to={'/checkout/shipping'}>
					<Button className="outline-btn add-address-btn">
						Add New Address
					</Button>
				</Link>
				<Button
					disabled={addressList.length === 0}
					className="gradient-btn select-address-btn"
					onClick={() => onAddressSelected()}
				>
					Select Address
				</Button>
			</div>
		</Col>
	);
};

export default UserAddressCard;
