import React from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Card,
  Image,
  Flex,
} from "antd";
import "./homeStyle/thirdSection.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import Egh from "../../assets/brands/egh.jpg"
import Corrit from "../../assets/brands/corrit.png"
import Evolet from "../../assets/brands/evolet.png"
import Herald from "../../assets/brands/herald.png"
import Hop from "../../assets/brands/hop.png"
import Huse from "../../assets/brands/huse_segway_logo.jpeg"
import Motorad from "../../assets/brands/motorad.png"
import Power from "../../assets/brands/power.png"
import Truebikes from "../../assets/brands/truebikes_logo.jpeg"
import Virtus from "../../assets/brands/Virtus.png"
import Xorbit from "../../assets/brands/xorbit.jpeg"

const { Text } = Typography;

const ThirdSection_Brands = () => {
  const navigate = useNavigate();
  const brands = [
    { name: "Herald", url: "https://heraldebike.com/", src: Herald },
    { name: "Emotorad", url: "https://www.emotorad.com/", src: Motorad },
    { name: "Corrit Electric", url: "https://www.corritelectric.com/", src: Corrit },
    { name: "Huse", url: "https://huse.co.in/", src: Huse },
    { name: "Power Electric", url: "https://powerelectricvehicles.co.in/", src: Power },
    { name: "Virtus Motors", url: "https://virtusmotors.in/", src: Virtus },
    { name: "Tru Bike", url: "https://tru.bike/", src: Truebikes },
    { name: "Evoletindia", url: "https://www.evoletindia.com/", src: Evolet },
    { name: "Xorbit EV", url: "https://xorbitev.com/", src: Xorbit },
    { name: "Vegh", url: "https://www.thevegh.co.in/", src: Egh },
    { name: "Hop Electric", url: "https://hopelectric.in/", src: Hop }
  ];

  const slickSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const slider = React.createRef();

  return (
    <Row className="third-section-container">
      <Col xs={24} md={12} lg={10} className="third-left-container">
        <Col className="third-left-sub-container">
          <Text className="third-title">Our Popular Brands</Text>
          <Text className="third-description">
            Take a glance at the most loved & wanted EVs brands of the month
          </Text>
          <Flex wrap="wrap" className="desktop-view">
            <div className="slick-btn-container">
              <Button
                className="btn-prev"
                onClick={() => slider?.current?.slickPrev()}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.165 12.0066L13.1634 12.3607C13.1513 13.7652 13.0666 15.0183 12.9206 15.8124C12.9206 15.8267 12.7613 16.6143 12.6599 16.8764C12.5006 17.2554 12.2126 17.577 11.8515 17.7808C11.5624 17.9262 11.2592 18 10.9417 18C10.6922 17.9884 10.2806 17.8629 9.98692 17.7576L9.74283 17.664C8.12612 17.0218 5.03561 14.9234 3.85199 13.6402L3.76473 13.5505L3.37527 13.1302C3.12982 12.8085 3 12.4153 3 11.9923C3 11.6134 3.11563 11.2344 3.3469 10.9282C3.41614 10.829 3.52766 10.7017 3.62693 10.5942L4.006 10.1974C5.31046 8.87572 8.13485 7.02183 9.59883 6.40756C9.59883 6.39435 10.5086 6.01432 10.9417 6H10.9995C11.6639 6 12.2846 6.37892 12.6021 6.99137C12.6888 7.1588 12.772 7.48678 12.8352 7.77483L12.949 8.31871C13.0788 9.19332 13.165 10.535 13.165 12.0066ZM19.4967 10.4817C20.3269 10.4817 21 11.1613 21 11.9996C21 12.8378 20.3269 13.5175 19.4967 13.5175L15.7975 13.1903C15.1463 13.1903 14.6183 12.6583 14.6183 11.9996C14.6183 11.3419 15.1463 10.8088 15.7975 10.8088L19.4967 10.4817Z" fill="#CBD1DC" />
                </svg>

              </Button>
              <Button
                className="btn-next"
                onClick={() => slider?.current?.slickNext()}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.835 12.0066L10.8366 12.3607C10.8487 13.7652 10.9334 15.0183 11.0794 15.8124C11.0794 15.8267 11.2387 16.6143 11.3401 16.8764C11.4994 17.2554 11.7874 17.577 12.1485 17.7808C12.4376 17.9262 12.7408 18 13.0583 18C13.3078 17.9884 13.7194 17.8629 14.0131 17.7576L14.2572 17.664C15.8739 17.0218 18.9644 14.9234 20.148 13.6402L20.2353 13.5505L20.6247 13.1302C20.8702 12.8085 21 12.4153 21 11.9923C21 11.6134 20.8844 11.2344 20.6531 10.9282C20.5839 10.829 20.4723 10.7017 20.3731 10.5942L19.994 10.1974C18.6895 8.87572 15.8652 7.02183 14.4012 6.40756C14.4012 6.39435 13.4914 6.01432 13.0583 6H13.0005C12.3361 6 11.7154 6.37892 11.3979 6.99137C11.3112 7.1588 11.228 7.48678 11.1648 7.77483L11.051 8.31871C10.9212 9.19332 10.835 10.535 10.835 12.0066ZM4.50325 10.4817C3.67308 10.4817 3 11.1613 3 11.9996C3 12.8378 3.67308 13.5175 4.50325 13.5175L8.20248 13.1903C8.85375 13.1903 9.38174 12.6583 9.38174 11.9996C9.38174 11.3419 8.85375 10.8088 8.20248 10.8088L4.50325 10.4817Z" fill="#CBD1DC" />
                </svg>

              </Button>
            </div>
          </Flex>
        </Col>
      </Col>
      <Col xs={24} md={12} lg={14} className="third-right-container-box">
        <div style={{ width: "100%" }}>
          <Slider
            {...slickSettings}
            ref={slider}
            className="third-section-slick-slider"
          >
            {brands && brands.length > 0 ? (
              brands.map((brand, index) => {
                return (
                  <div className="scooty-card-wrapper" key={index}>
                    <a href={brand.url} target="_blank" rel="noopener noreferrer">
                      <Card hoverable className="third-card">
                        <Col
                          span={24}
                          className="third-card-img-container"
                        >
                          <Image
                            className="third-card-img"
                            alt={brand.name}
                            src={brand.src}
                            style={{ maxHeight: "100px", objectFit: "contain" }}
                            preview={false}
                          />
                        </Col>
                        <Col span={24} className="card-details-container">
                          <Text className="third-card-title">
                            {brand.name}
                          </Text>
                        </Col>
                      </Card>
                    </a>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </Slider>
        </div>

      </Col>
      <Flex wrap="wrap" className="small">
        <Button
          className="third-view-all-btn"
          onClick={() => navigate("/products")}
        >
          View All
        </Button>
        <div className="slick-btn-container">
          <Button
            className="btn-prev"
            onClick={() => slider?.current?.slickPrev()}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.165 12.0066L13.1634 12.3607C13.1513 13.7652 13.0666 15.0183 12.9206 15.8124C12.9206 15.8267 12.7613 16.6143 12.6599 16.8764C12.5006 17.2554 12.2126 17.577 11.8515 17.7808C11.5624 17.9262 11.2592 18 10.9417 18C10.6922 17.9884 10.2806 17.8629 9.98692 17.7576L9.74283 17.664C8.12612 17.0218 5.03561 14.9234 3.85199 13.6402L3.76473 13.5505L3.37527 13.1302C3.12982 12.8085 3 12.4153 3 11.9923C3 11.6134 3.11563 11.2344 3.3469 10.9282C3.41614 10.829 3.52766 10.7017 3.62693 10.5942L4.006 10.1974C5.31046 8.87572 8.13485 7.02183 9.59883 6.40756C9.59883 6.39435 10.5086 6.01432 10.9417 6H10.9995C11.6639 6 12.2846 6.37892 12.6021 6.99137C12.6888 7.1588 12.772 7.48678 12.8352 7.77483L12.949 8.31871C13.0788 9.19332 13.165 10.535 13.165 12.0066ZM19.4967 10.4817C20.3269 10.4817 21 11.1613 21 11.9996C21 12.8378 20.3269 13.5175 19.4967 13.5175L15.7975 13.1903C15.1463 13.1903 14.6183 12.6583 14.6183 11.9996C14.6183 11.3419 15.1463 10.8088 15.7975 10.8088L19.4967 10.4817Z" fill="#CBD1DC" />
            </svg>

          </Button>
          <Button
            className="btn-next"
            onClick={() => slider?.current?.slickNext()}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M10.835 12.0066L10.8366 12.3607C10.8487 13.7652 10.9334 15.0183 11.0794 15.8124C11.0794 15.8267 11.2387 16.6143 11.3401 16.8764C11.4994 17.2554 11.7874 17.577 12.1485 17.7808C12.4376 17.9262 12.7408 18 13.0583 18C13.3078 17.9884 13.7194 17.8629 14.0131 17.7576L14.2572 17.664C15.8739 17.0218 18.9644 14.9234 20.148 13.6402L20.2353 13.5505L20.6247 13.1302C20.8702 12.8085 21 12.4153 21 11.9923C21 11.6134 20.8844 11.2344 20.6531 10.9282C20.5839 10.829 20.4723 10.7017 20.3731 10.5942L19.994 10.1974C18.6895 8.87572 15.8652 7.02183 14.4012 6.40756C14.4012 6.39435 13.4914 6.01432 13.0583 6H13.0005C12.3361 6 11.7154 6.37892 11.3979 6.99137C11.3112 7.1588 11.228 7.48678 11.1648 7.77483L11.051 8.31871C10.9212 9.19332 10.835 10.535 10.835 12.0066ZM4.50325 10.4817C3.67308 10.4817 3 11.1613 3 11.9996C3 12.8378 3.67308 13.5175 4.50325 13.5175L8.20248 13.1903C8.85375 13.1903 9.38174 12.6583 9.38174 11.9996C9.38174 11.3419 8.85375 10.8088 8.20248 10.8088L4.50325 10.4817Z" fill="#CBD1DC" />
            </svg>

          </Button>
        </div>
      </Flex>
    </Row>
  );
};

export default ThirdSection_Brands;
