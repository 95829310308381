import { Checkbox, Select } from 'antd';
import { useEffect, useState } from 'react';
import './Filters.css';

const LocationFilter = ({ onLocationChange, isResetFilter }) => {
	const locations = [
		{ label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
		{ label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
		{ label: 'Assam', value: 'Assam' },
		{ label: 'Bihar', value: 'Bihar' },
		{ label: 'Chhattisgarh', value: 'Chhattisgarh' },
		{ label: 'Goa', value: 'Goa' },
		{ label: 'Gujarat', value: 'Gujarat' },
		{ label: 'Haryana', value: 'Haryana' },
		{ label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
		{ label: 'Jharkhand', value: 'Jharkhand' },
		{ label: 'Karnataka', value: 'Karnataka' },
		{ label: 'Kerala', value: 'Kerala' },
		{ label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
		{ label: 'Maharashtra', value: 'Maharashtra' },
		{ label: 'Manipur', value: 'Manipur' },
		{ label: 'Meghalaya', value: 'Meghalaya' },
		{ label: 'Mizoram', value: 'Mizoram' },
		{ label: 'Nagaland', value: 'Nagaland' },
		{ label: 'Odisha', value: 'Odisha' },
		{ label: 'Punjab', value: 'Punjab' },
		{ label: 'Rajasthan', value: 'Rajasthan' },
		{ label: 'Sikkim', value: 'Sikkim' },
		{ label: 'Tamil Nadu', value: 'Tamil Nadu' },
		{ label: 'Telangana', value: 'Telangana' },
		{ label: 'Tripura', value: 'Tripura' },
		{ label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
		{ label: 'Uttarakhand', value: 'Uttarakhand' },
		{ label: 'West Bengal', value: 'West Bengal' },
		{
			label: 'Andaman and Nicobar Islands',
			value: 'Andaman and Nicobar Islands',
		},
		{ label: 'Chandigarh', value: 'Chandigarh' },
		{
			label: 'Dadra and Nagar Haveli and Daman and Diu',
			value: 'Dadra and Nagar Haveli and Daman and Diu',
		},
		{ label: 'Delhi', value: 'Delhi' },
		{ label: 'Lakshadweep', value: 'Lakshadweep' },
		{ label: 'Puducherry', value: 'Puducherry' },
	];

	const [selectedLocations, setSelectedLocations] = useState([]);

	const handleColorChange = (selected) => {
		setSelectedLocations(selected);

		if (onLocationChange) {
			onLocationChange(selected);
		}
	};

	const onSearch = (value) => {};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	useEffect(() => {
		if (isResetFilter) {
			setSelectedLocations([]);
		}
	}, [isResetFilter]);

	return (
		<div style={{ marginBottom: '16px' }}>
			<h4 className="filter-header">Location</h4>
			{/* <Checkbox.Group
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
        className="custom-checkbox"
        options={locations}
        value={selectedLocations}
        onChange={handleColorChange}
      /> */}
			<Select
				style={{
					width: '100%',
					fontFamily: 'AvenirNextLTPro',
					fontSize: '14px',
					fontWeight: '600',
					color: '#000',
				}}
				showSearch
				placeholder="Select a state"
				optionFilterProp="children"
				onChange={handleColorChange}
				onSearch={onSearch}
				filterOption={filterOption}
				options={locations}
				value={selectedLocations}
			/>
		</div>
	);
};
export default LocationFilter;
