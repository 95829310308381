import { useEffect, useState } from "react";
import { Col, Typography, Image, Card, Button, Rate, message, Flex } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import vector5 from "../../assets/images/Vector5.svg";
import "./style/product.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCartItem } from "../../Redux/actionTypes";
import { addProductToCart, getUserCartList, userLogin } from "../../api/user";
import { initOTPless } from "../../utils/initOtpless";
import { formatToRupees } from "../../utils/formatToRupees";

const { Text } = Typography;

const Product = ({
  product,
  isCompare = false,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [userData, setUserData] = useState(
    localStorage.getItem("otpless_user_token")
  );
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [isItemAddeToCart, setIsItemAddeToCart] = useState(false);
  const authState = useSelector((state) => (state ? state.auth : []));
  const isUnavailable =
    authState.userCoords !== undefined && authState.userPincode !== undefined
      ? // ? !product?.availableLocations?.includes(Object.values(authState.userPincode))
      !product?.availableLocations?.some((r) =>
        Object.values(authState.userPincode).includes(r)
      )
      : true;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = (e) => {
    const modalContainer = document.getElementById("modalContainer");
    modalContainer.style.display = "none";
  };

  const callback = async (otplessUser) => {
    const tokenObj = {
      token: otplessUser.token,
      email: otplessUser.email?.email,
      waNumber: otplessUser.waNumber,
      waName: otplessUser.email?.name !== null ? otplessUser.email?.name : "",
    };
    const userData = await userLogin(tokenObj);
    const { auth_token, ...userInfo } = userData.data.data;
    // Save token and user info in localStorage
    localStorage.setItem("auth_token", auth_token);
    localStorage.setItem("user_details", JSON.stringify(userInfo));
    localStorage.setItem("otpless_user_token", JSON.stringify(tokenObj));
    setUserData(otplessUser);
    getUserCartList().then((response) => {
      dispatch(updateCartItem(response));
    });
    closeModal();
  };

  const openModal = () => {
    if (userData) {
      return;
    }
    initOTPless(callback);
    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.style.display = "flex";
    }
    // modalContainer !== null ? (modalContainer.style.display = "flex") : "";
  };

  const handleAddToCart = (itemDetails) => {
    navigate(`/product/${itemDetails.id}`);
    // const otpless_user_token = JSON.parse(
    //   localStorage.getItem("otpless_user_token")
    // );

    // if (!otpless_user_token) {
    //   openModal();
    // } else {
    //   const productDetailsAndCount = {
    //     product_id: itemDetails.id,
    //     count: 1,
    //   };
    //   addProductToCart(productDetailsAndCount).then((response) => {
    //     getUserCartList().then((cartResponse) => {
    //       message.success("Item Added to cart");
    //       dispatch(updateCartItem(cartResponse));
    //     });
    //   });
    //   // dispatch(addToCart(productDetailsAndCount)); // Dispatch action to add item to cart
    //   setIsItemAddeToCart(true);
    // }
  };

  const wishlistIconColor = isWishlistClicked ? "red" : "gray"; // Define the colors
  const productImage = product?.productImages?.split(",")?.[0];
  const handleWishlistClick = () => {
    setIsWishlistClicked(!isWishlistClicked); // Toggle the state
  };

  const total_rating_count = () => {
    if (product?.reviews?.length > 0) {
      const rating_count = product.reviews.map((e) => e.rating)
      const total_rating = rating_count.reduce((a, b) => a + b, 0);
      const average_rating = (total_rating / rating_count.length) || 0;
      setAverageRating(average_rating)
    }
  }

  useEffect(() => {
    total_rating_count()
  }, [])

  return (
    <>
      {isCompare ? (
        <Card
          onClick={() => setSelectedProduct(product)}
          hoverable
          style={selectedProduct === product && { border: "2px solid #04b2a9", backgroundColor: "#000000", padding: "0px" }}
          className="third-card-hover"
        >
          <Col span={24} className="third-card-img-container">
            <Image
              className="third-card-img"
              alt="example"
              width={140}
              src={productImage || vector5}
              preview={false}
              style={{ maxHeight: "100px", objectFit: "contain" }}
            />

            {/* <HeartOutlined
              className="wishlist-icon"
              style={{
                fontSize: "24px",
                position: "absolute",
                top: "10px",
                right: "10px",
                color: wishlistIconColor,
              }}
              onClick={handleWishlistClick}
            /> */}
          </Col>
          <Col span={24} className="card-details-container">
            <div style={{ padding: '5px 0' }}>
              <Text className="product-card-title" style={{ color: "#fff", fontSize: "16px" }}>
                {product.name?.length > 8 ? product.name.slice(0, 15) + "..." : product.name}
              </Text>
              <div style={{ marginTop: "7px", color: "#00ffff" }}>
                {product.brandName?.length > 8 ? product.brandName.slice(0, 14) : product.brandName}
                {/* <Rate allowHalf disabled value={product.ratings} style={{ color: '#FF8F28', margin: '5px 0 15px 0' }} /> */}
              </div>
              <Flex gap="large">
                <Text className="product-card-discounted-price">
                  ₹{formatToRupees(product.sellingPrice)}
                </Text>
              </Flex>
            </div>
            {/* <Button
              // disabled={isUnavailable}
              // type={isUnavailable ? "dashed" : ""}
              // danger={isUnavailable}
              className={"third-view-all-btn"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Stop event propagation
              }}
            >
          
              View
            </Button> */}
          </Col>
        </Card>
      ) : (
        <Link to={`/product/${product.id}`}>
          <Card hoverable className="third-card">
            <Col span={24} className="third-card-img-container">
              {/* {isUnavailable && (
          <div className="unavailable-text">
            <Text type="danger">
              Product is not available for your location
            </Text>
          </div>
        )} */}

              <Image
                className="third-card-img"
                alt="example"
                width={200}
                src={productImage || vector5}
                preview={false}
                style={{ height: "130px" }}
              />

              <HeartOutlined
                className="wishlist-icon"
                style={{
                  fontSize: "24px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: wishlistIconColor,
                }}
                onClick={handleWishlistClick}
              />
            </Col>
            <Col span={24} className="card-details-container">
              <Text className="third-card-title" style={{ color: "#ffff" }}>
                {product.name?.length > 8 ? product.name.slice(0, 10) + "..." : product.name}
              </Text>
              <Rate allowHalf disabled defaultValue={product.ratings || 1} />
              <div>
                <div>
                  <Text className="third-card-price" delete>
                    ₹{product.productPrice}
                  </Text>
                  <Text className="third-card-price">
                    {" "}
                    - {product.discount}%
                  </Text>
                </div>
                <div>
                  <Text className="third-card-price" style={{ color: "#00FFFF" }}>
                    ₹{product.sellingPrice}
                  </Text>
                </div>
              </div>
              <Button
                // disabled={isUnavailable}
                // type={isUnavailable ? "dashed" : ""}
                // danger={isUnavailable}
                className={"third-view-all-btn"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Stop event propagation
                  handleAddToCart(product);
                }}
              >
                {/* {isUnavailable
              ? "Product is not available for your location"
              // : "Add to cart"}
              : "View"} */}
                View
              </Button>
            </Col>
          </Card>
        </Link>
      )}
    </>
  );
};

export default Product;
