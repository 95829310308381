import { combineReducers } from "redux";
import cartReducer from "./CartReducer";
import authReducer from "./authReducer";
import productReducer from "./productReducer";

const rootReducer = combineReducers({
  cart: cartReducer,
  auth: authReducer,
  product: productReducer
});

export default rootReducer;
