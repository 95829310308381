import { message } from "antd";
import authApiService from "../api/authApiService";

export const getUserPincode = async ({ lat, long }) => {
  //   let location = window.localStorage.getItem("userLocation");
  //   if (location !== null) {
  // location = JSON.parse(location);
  // const latitude = location.lat;
  // const longitude = location.long;
  console.log("%%%%%%%%%%%%%%%%%%%%");
  try {
    const { data } = await authApiService.post("/auth/user/getPincode", {
      lat,
      long,
    });
    if (data) {
      data.data['pincode'] = data.pincode
      return data.data;
    }
  } catch (error) {
    message.error("Failed to get pincode!");
  }
  //   }
};
