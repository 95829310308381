import { Col, Divider, Flex, Rate, Row, Table, Typography } from 'antd';
import './style.css'
import { formatDate } from '../../../utils/data-time';
const { Text } = Typography;

const Review = ({ review }) => {
  return (
    <div className='review-section'>
      <Flex>
        <div className='review-section-container'>
          <div className='star-display'>
            {review.username && <p>{review?.username.split("")[0]}</p>}
          </div>
        </div>
        <div style={{ marginTop: '5px' }}>
          <Row>
            <Col>
              {review.username && <Text className='user-name'>{review.username}</Text>}
              <span>&#9679;</span>
              <Text className='review-date'>{formatDate(Number(review.dateReviewed))}</Text>
            </Col>
          </Row>
          <div style={{ marginLeft: '20px' }}>
            <Row style={{ padding: '5px 0 15px 0' }}>
              <Col>
                <Rate
                  value={review.rating}
                  style={{
                    fontSize: 22,
                    color: "gold",
                    marginRight: "16px",
                    paddingTop: "10px",
                    color: '#FF902D'
                  }}
                  disabled={true}
                />
              </Col>
            </Row>
            <Text className='review-text'>{review.review}</Text>
          </div>
        </div>
      </Flex>
    </div>
  )
}

const Reviews = ({ reviews }) => {
  const dataSource = reviews.map((review, index) => {
    return {
      key: index,
      review: <Review review={review} />
    }
  });

  const columns = [
    {
      title: '',
      dataIndex: 'review',
      key: 'name'
    }
  ];

  return (
    <>
      {reviews && reviews?.length === 0 ? (
        <Text className='no-review-text'>No review available for this product!</Text>
      ) :
        <Table
          dataSource={dataSource}
          columns={columns}
          className='reviews-table'
          pagination={{ defaultPageSize: 5 }}
        />
      }
    </>
  )
}

export default Reviews;