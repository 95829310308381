import { Col, Spin, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatToRupees } from "../../utils/formatToRupees";

const OrderSummary = ({ getOrderTotalAmount }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(50);
  const [gstCharge, setGstCharge] = useState(0);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const getTotalAmount = (cartItems) => {
    
    setIsLoading(true)
    let subTotal = 0;
    let totalAmount = 0;
    let charge = 0;
    let gst = 0;
    if (cartItems.length) {
      cartItems.forEach((item) => {
        subTotal += (item.booking_type == "booking") ? (item.bookingPrice * item.count) : (((item.variant && item.variant != null) ? getVariantPrice(item) : item.sellingPrice) * item.count);
        if (!item.priceIncludesDelivery) {
          charge += ((item.booking_type == "booking") ? 0 : ((item.variant && item.variant != null) ? (getVariantPrice(item) * item.count) : (item.sellingPrice * item.count)) * 5) / 100
        }
      });
    }
    totalAmount = subTotal + charge
    gst = (subTotal * 5) / 100
    totalAmount += gst
    setSubTotalAmount(subTotal.toFixed(2))
    setTotalAmount(totalAmount.toFixed(2));
    setShippingCharge(charge.toFixed(2));
    setGstCharge(gst.toFixed(2))
    setIsLoading(false)
    getOrderTotalAmount(totalAmount.toFixed(2))
  };

  const getVariantPrice = (cartItem) => {

    if (cartItem && cartItem.variants && cartItem.variants.length) {
      let variant = cartItem.variant;
      let selectedVariant = cartItem.variants.filter(item => item.spec === variant);

      if (selectedVariant.length > 0) {
        return selectedVariant[0].price;
      }
    }
    return cartItem.sellingPrice;
  };

  useEffect(() => {
    getTotalAmount(cartItems);
  }, [cartItems]);

  return (
    <Col xs={24} sm={24} md={12}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
          <Spin />
        </div>
      ) : (
        <div style={{ padding: "0 20px" }}>
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
              paddingTop: "20px",
              fontFamily: 'AvenirNextLTPro'
            }}
          >
            Total amount
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              color: "#04B2A9",
              fontSize: "40px",
              fontWeight: "bolder",
              fontFamily: 'AvenirNextLTPro'
            }}
          >
            Rs.{formatToRupees(totalAmount)}
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              color: "#ffff",
              paddingBottom: "40px",
              borderBottom: "1px solid #E3E3E3", // Adding bottom border
              marginBottom: "30px",
              fontFamily: 'AvenirNextLTPro'
            }}
          >
            <LockOutlined style={{ color: "#00FFFF", marginRight: "2px" }} />
            Secure Payment
          </Typography>
          <Typography style={{ color: "#848397", fontSize: "18px", fontFamily: 'AvenirNextLTPro' }}>
            Order Summary
          </Typography>
          <div
            style={{
              borderBottom: "1px solid #E3E3E3",
              marginTop: "20px",
              paddingBottom: "20px",
            }}
          >
            {cartItems?.length ? (
              cartItems.map((item, index) => (
                <Typography
                  key={index}
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-between",

                  }}
                >
                  <div style={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    display: "flex",
                  }}>
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                      <span style={{ textAlign: "left", fontFamily: 'AvenirNextLTPro', color: '#fff' }}>{item?.name?.length > 8 ? item?.name?.slice(0, 10) + "..." : item?.name}</span>{" "}
                      <span
                        style={{
                          color: "#fff",
                          fontWeight: "normal",
                          fontSize: "16px",
                          fontFamily: 'AvenirNextLTPro',
                          marginLeft: '10px'
                        }}
                      >
                        {item.count ? `x${item.count}` : ""}
                      </span>
                    </div>
                    <span style={{ color: "#fff" }}>
                      {item.brandName}
                    </span>
                    <span style={{ color: "#fff" }}>
                      {item.variant} - {item.selectedColor}
                    </span>
                  </div>
                  <span style={{ textAlign: "right", color: '#ffff', fontFamily: 'AvenirNextLTPro' }}>
                    Rs.{item.booking_type == "booking" ? formatToRupees(item.bookingPrice * item.count) : formatToRupees((item.variant && item.variant != null) ? getVariantPrice(item) * item.count : item.sellingPrice * item.count)}
                  </span>
                </Typography>
              ))
            ) : (
              <></>
            )}
          </div>
          <Typography
            style={{
              marginTop: "20px",
              fontSize: "20px",
              display: "flex",
              padding: '5px 0',
              justifyContent: "space-between",
              color: "#fff"
            }}
          >
            <span style={{ textAlign: "left", color: "#fff", fontSize: "18px", fontFamily: 'AvenirNextLTPro' }}>
              Subtotal
            </span>
            <span style={{ textAlign: "right", fontWeight: "bold", color: '#ffff', fontFamily: 'AvenirNextLTPro' }}>
              Rs.{formatToRupees(subTotalAmount)}
            </span>
          </Typography>
          <Typography
            style={{
              fontSize: "20px",
              display: "flex",
              padding: '5px 0',
              justifyContent: "space-between"
            }}
          >
            <span style={{ textAlign: "left", color: "#fff", fontSize: "18px", fontFamily: 'AvenirNextLTPro' }}>
              Shipping
            </span>
            <span style={{ textAlign: "right", fontWeight: "bold", color: '#fff', fontFamily: 'AvenirNextLTPro' }}>
              {shippingCharge > 0 ? "Rs." + formatToRupees(shippingCharge) : "Free"}
            </span>
          </Typography>

          <Typography
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "space-between",
              padding: '5px 0 40px 0',
              borderBottom: "1px solid #E3E3E3"
            }}
          >
            <span style={{ textAlign: "left", color: "#fff", fontSize: "18px", fontFamily: 'AvenirNextLTPro' }}>
              GST Charges
            </span>
            <span style={{ textAlign: "right", fontWeight: "bold", color: '#fff', fontFamily: 'AvenirNextLTPro' }}>
              {"Rs." + formatToRupees(gstCharge)}
            </span>
          </Typography>

          <Typography
            style={{
              margin: "20px 0",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ textAlign: "left", fontSize: "22px", fontFamily: 'AvenirNextLTPro' }}>Total</span>
            <span
              style={{
                textAlign: "right",
                fontFamily: 'AvenirNextLTPro',
                color: "#00ffff",
                fontSize: "30px",
              }}
            >
              Rs.{formatToRupees(totalAmount)}
            </span>
          </Typography>
        </div>
      )}
    </Col>
  );
};

export default OrderSummary;
