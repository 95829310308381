export const setProductData = (data) => ({
    type: "SET_PRODUCTS",
    payload: data,
});

export const setBrandsData = (data) => ({
    type: "SET_BRANDS",
    payload: data
})

export const setCategoriesData = (data) => ({
    type: "SET_CATEGORIES",
    payload: data
})

export const setProductColors = (data) => ({
    type: "SET_PRODUCT_COLORS",
    payload: data
})

export const setProductMaxPrice = (data) => ({
    type: "SET_PRODUCT_MAX_PRICE",
    payload: data
})