import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Input,
  Button,
  Space,
  message,
  Form,
  Spin,
} from "antd";

import "./contactStyle/secondSection.css";
import { useForm } from "antd/lib/form/Form";
import authApiService from "../../api/authApiService";
const { Text } = Typography;

const SecondSection = () => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (body: any) => {
    try {
      setIsLoading(true);
      const response = await authApiService.post("/auth/subscribe-us", body);
      setIsLoading(false);
      if (response.data.error === false) {
        message.success("Your request submitted successfully!");
      } else {
        message.error("Failed to submit request!");
      }
    } catch (error) {
      message.error("Failed to submit request!");
    }
  };

  return (
    <Row className="contact-second-section-container">
      <Col xs={23} md={22} lg={16} className="contact-second-sub-container">
        <Text className="contact-second-title">
          Subscribe to our newsletter
        </Text>
        <Text className="contact-second-description">
          and learn more about us
        </Text>
        <Form form={form} onFinish={handleSubmit}>
          <Space.Compact className="contact-space-container">
            <Form.Item
              className="contact-form-item"
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                {
                  pattern: new RegExp(
                    "^([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$"
                  ),
                  message: "please enter correct email",
                },
              ]}
            >
              <Input
                className="contact-subscribe-input"
                placeholder="Your email"
              />
            </Form.Item>
            <Button htmlType="submit" className="contact-subscribe-btn">
              {isLoading ? (
                <Spin className="ant-spin-dot-item-custom" />
              ) : (
                "Subscribe"
              )}
            </Button>
          </Space.Compact>
        </Form>
      </Col>
    </Row>
  );
};

export default SecondSection;
