import { Input} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './style/productInputs.css';

const ProductSearchInput = ({ onTextEntered }) => {
  const handleInputChange = (e) => {
    onTextEntered(e.target.value);
  };
  return (
    <>
      <Input
        placeholder="Search"
        size="large"
        suffix={<SearchOutlined />}
        onChange={handleInputChange}
        className='product-search-input'
      />
    </>
  );
};

export default ProductSearchInput;
