import React from 'react'
import './style.css'

const Overview = ({ productData }) => {
    return (
        <table className='overview-table'>
            <tr>
                <td>&#9679; Range</td>
                <td>{productData.range_Overview ? `${productData.range_Overview} km`: '-'}</td>
            </tr>
            <tr>
                <td>&#9679; Top Speed</td>
                <td>{productData.topSpeed_Overview ? `${productData.topSpeed_Overview} km/h` : '-'}</td>
            </tr>
            <tr>
                <td>&#9679; Charging Time</td>
                <td>{productData.chargingTime ? `${productData.chargingTime} hours` : '-'}</td>
            </tr>
            {/* <tr>
                <td>&#9679; Acceleration 0-40 km/H</td>
                <td>{productData.topSpeed_Overview ? productData.topSpeed_Overview : '-'}</td>
            </tr> */}
            <tr>
                <td>&#9679; Safety</td>
                <td>{productData.safety ? productData.safety : '-'}</td>
            </tr>
            <tr>
                <td>&#9679; Transmission</td>
                <td>{productData.transmission_Overview ? productData.transmission_Overview : '-'}</td>
            </tr>
        </table>
    )
}

export default Overview