import React from "react";
import { Row, Col, Image, Typography, Carousel, Button, Flex } from 'antd'
import vector6 from "../assets/images/Vector6.svg"
import vector7 from "../assets/images/Vector7.svg"
import vector8 from "../assets/images/Vector8.svg"
import user from "../assets/images/image.png"
import "./style.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrowLeft from '../../src/assets/images/arrowLeft.svg'
import arrowRight from '../../src/assets/images/arrowRight.svg'
import iconlyRight from '../../src/assets/images/iconlyRight.svg'
import iconlyLeft from '../../src/assets/images/iconlyLeft.svg'

const { Text } = Typography

const slider = React.createRef();

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },

        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export default function ClientTestimonials() {
    const testimonials = [
        {
            image: user,
            name: "Rohit Varshney",
            message: "The bike and the service rendered by your company was really outstanding! My best wishes to your venture!!"
        },
        {
            image: user,
            name: "Naman Nath",
            message: "Recently ordered a e-cycle from Lilypad and the experience was seamless and delivered within 2 weeks!"
        },
        {
            image: user,
            name: "Tanisha Gambhir",
            message: "I was looking to learn about 2 wheeler electric vehicles and found lilypad, was able to compare products and read more about them. Truly a one stop shop."
        },
    ]

    return (
        <Row className='client-section-container'>
            <Col xs={24} sm={24} md={10} lg={12} className='client-left-container'>
                <Col span={24} className='client-left-sub-container'>
                    <div style={{ width: '100%' }}>
                        <Text className='client-title'>Client <br />Testimonials</Text>

                    </div>
                    <Flex gap="large" wrap="wrap">
                        <div className="slick-btn-container client-test-slick-btn-container">
                            <Button className="btn-prev" onClick={() => slider?.current?.slickPrev()}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.165 12.0066L13.1634 12.3607C13.1513 13.7652 13.0666 15.0183 12.9206 15.8124C12.9206 15.8267 12.7613 16.6143 12.6599 16.8764C12.5006 17.2554 12.2126 17.577 11.8515 17.7808C11.5624 17.9262 11.2592 18 10.9417 18C10.6922 17.9884 10.2806 17.8629 9.98692 17.7576L9.74283 17.664C8.12612 17.0218 5.03561 14.9234 3.85199 13.6402L3.76473 13.5505L3.37527 13.1302C3.12982 12.8085 3 12.4153 3 11.9923C3 11.6134 3.11563 11.2344 3.3469 10.9282C3.41614 10.829 3.52766 10.7017 3.62693 10.5942L4.006 10.1974C5.31046 8.87572 8.13485 7.02183 9.59883 6.40756C9.59883 6.39435 10.5086 6.01432 10.9417 6H10.9995C11.6639 6 12.2846 6.37892 12.6021 6.99137C12.6888 7.1588 12.772 7.48678 12.8352 7.77483L12.949 8.31871C13.0788 9.19332 13.165 10.535 13.165 12.0066ZM19.4967 10.4817C20.3269 10.4817 21 11.1613 21 11.9996C21 12.8378 20.3269 13.5175 19.4967 13.5175L15.7975 13.1903C15.1463 13.1903 14.6183 12.6583 14.6183 11.9996C14.6183 11.3419 15.1463 10.8088 15.7975 10.8088L19.4967 10.4817Z" fill="#CBD1DC" />
                                </svg>
                            </Button>
                            <Button className="btn-next" onClick={() => slider?.current?.slickNext()}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.835 12.0066L10.8366 12.3607C10.8487 13.7652 10.9334 15.0183 11.0794 15.8124C11.0794 15.8267 11.2387 16.6143 11.3401 16.8764C11.4994 17.2554 11.7874 17.577 12.1485 17.7808C12.4376 17.9262 12.7408 18 13.0583 18C13.3078 17.9884 13.7194 17.8629 14.0131 17.7576L14.2572 17.664C15.8739 17.0218 18.9644 14.9234 20.148 13.6402L20.2353 13.5505L20.6247 13.1302C20.8702 12.8085 21 12.4153 21 11.9923C21 11.6134 20.8844 11.2344 20.6531 10.9282C20.5839 10.829 20.4723 10.7017 20.3731 10.5942L19.994 10.1974C18.6895 8.87572 15.8652 7.02183 14.4012 6.40756C14.4012 6.39435 13.4914 6.01432 13.0583 6H13.0005C12.3361 6 11.7154 6.37892 11.3979 6.99137C11.3112 7.1588 11.228 7.48678 11.1648 7.77483L11.051 8.31871C10.9212 9.19332 10.835 10.535 10.835 12.0066ZM4.50325 10.4817C3.67308 10.4817 3 11.1613 3 11.9996C3 12.8378 3.67308 13.5175 4.50325 13.5175L8.20248 13.1903C8.85375 13.1903 9.38174 12.6583 9.38174 11.9996C9.38174 11.3419 8.85375 10.8088 8.20248 10.8088L4.50325 10.4817Z" fill="#CBD1DC" />
                                </svg>
                            </Button>
                        </div>
                    </Flex>
                </Col>
            </Col>

            <Col xs={24} sm={24} md={14} lg={12} className='client-right-container'>
                <Slider ref={slider} {...slickSettings} className="slick-slider-horizontal">
                    {testimonials.map((testimonial, index) =>
                        <div className="card-wrapper" key={index}>
                            <div className='client-card-container'>
                                <div className='client-card-content'>
                                    <Col span={24} className='client-card-avatar-container'>
                                        <Col>
                                            <Image preview={false} src={testimonial.image} width={70} className='client-card-avatar' />
                                        </Col>
                                        <Col className="client-card-name-container">
                                            <Text className='client-card-name'>{testimonial.name}</Text>
                                            {/* <Text className='client-card-name-secondary' type='secondary'>Freelancer</Text> */}
                                        </Col>
                                    </Col>
                                    <Col span={24} className='client-card-description-container'>
                                        <Text className='client-card-desc-text'>{testimonial.message}</Text>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="card-wrapper">
                        <div className='client-card-container'>
                            <div className='client-card-content'>
                                <Col span={24} className='client-card-avatar-container'>
                                    <Col>
                                        <Image preview={false} src={user} width={70} className='client-card-avatar' />
                                    </Col>
                                    <Col className="client-card-name-container">
                                        <Text className='client-card-name'>Ashly Wrayne</Text>
                                        <Text className='client-card-name-secondary' type='secondary'>Freelancer</Text>
                                    </Col>
                                </Col>
                                <Col span={24} className='client-card-description-container'>
                                    <Text className='client-card-desc-text'>Recently ordered a e-cycle from Lilypad and the experience was seamless and delivered within 2 weeks!</Text>
                                </Col>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className='client-card-container'>
                            <div className='client-card-content'>
                                <Col span={24} className='client-card-avatar-container'>
                                    <Col>
                                        <Image preview={false} src={user} width={70} className='client-card-avatar' />
                                    </Col>
                                    <Col className="client-card-name-container">
                                        <Text className='client-card-name'>Pitter Park</Text>
                                        <Text className='client-card-name-secondary' type='secondary'>Freelancer</Text>
                                    </Col>
                                </Col>
                                <Col span={24} className='client-card-description-container'>
                                    <Text className='client-card-desc-text'>I was looking to learn about 2 wheeler electric vehicles and found lilypad, was able to compare products and read more about them. Truly a one stop shop</Text>
                                </Col>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className='client-card-container'>
                            <div className='client-card-content'>
                                <Col span={24} className='client-card-avatar-container'>
                                    <Col>
                                        <Image preview={false} src={user} width={70} className='client-card-avatar' />
                                    </Col>
                                    <Col className="client-card-name-container">
                                        <Text className='client-card-name'>Ashly Wrayne</Text>
                                        <Text className='client-card-name-secondary' type='secondary'>Freelancer</Text>
                                    </Col>
                                </Col>
                                <Col span={24} className='client-card-description-container'>
                                    <Text className='client-card-desc-text'>The bike and the service rendered by your company was really outstanding! My best wishes to your venture!!</Text>
                                </Col>
                            </div>
                        </div>
                    </div>
                    <div className="card-wrapper">
                        <div className='client-card-container'>
                            <div className='client-card-content'>
                                <Col span={24} className='client-card-avatar-container'>
                                    <Col>
                                        <Image preview={false} src={user} width={70} className='client-card-avatar' />
                                    </Col>
                                    <Col className="client-card-name-container">
                                        <Text className='client-card-name'>Ashly Wrayne</Text>
                                        <Text className='client-card-name-secondary' type='secondary'>Freelancer</Text>
                                    </Col>
                                </Col>
                                <Col span={24} className='client-card-description-container'>
                                    <Text className='client-card-desc-text'>The bike and the service rendered by your company was really outstanding! My best wishes to your venture!!</Text>
                                </Col>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </Col>
        </Row >
    )
}
