
import { Col, Row, List, Empty } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard from "./ProductCard";

const ProductList = ({
  products,
  isLoading,
  fetchData,
  lastKey,
  isOverflow,
}) => {
  // const [productList, setProductList] = useState([]);

  // useEffect(() => {
  //   setProductList(products);
  // }, [products]);

  return (
    <>
      <InfiniteScroll
        style={{ height: 'auto', overflow: 'none' }}
        dataLength={products.length}
        next={() => {
          if (fetchData && lastKey) {
            fetchData(lastKey?.id);
          }
        }}
        hasMore={true}
        loader={isLoading}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <List
              className="product-list"
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 1,
                lg: 3,
                xl: 3,
                xxl: 4,
              }}
              locale={{
                emptyText: <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 60,
                    marginTop: '50px'
                  }}
                  description={
                    <span style={{ fontFamily: 'AvenirNextLTPro' }}>
                      No products available
                    </span>
                  }
                />
              }}
              loading={isLoading}
              dataSource={products}
              renderItem={(product) => (
                <List.Item>
                  <ProductCard product={product} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </InfiniteScroll>
    </>
  );
};

export default ProductList;
