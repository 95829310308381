import React, { useRef, useState } from 'react'
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Typography,
    Checkbox,
    Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { addAddress, editAddress } from "../api/user";
import { setEditedAddress, setAddedAddress } from '../Redux/actionTypes/authAction';
import { CaretDownOutlined } from '@ant-design/icons';
const { Item } = Form;
const { Option } = Select;
const states = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    { label: "Andaman and Nicobar Islands", value: "Andaman and Nicobar Islands" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Dadra and Nagar Haveli and Daman and Diu", value: "Dadra and Nagar Haveli and Daman and Diu" },
    { label: "Delhi", value: "Delhi" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Puducherry", value: "Puducherry" },
];

export const Address = ({ setIsNewAddressAdd, selectedAddress }) => {
    const userDetails = useSelector((state) => state.auth.userDetails);

    const dispatch = useDispatch();
    const [form] = useForm();
    const inputRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
        }
    };
    // form.setFieldsValue({
    //     email: userDetails?.email,
    // });
    // form.setFieldsValue({
    //     mobile: userDetails?.mobile,
    // });

    const initialValues = selectedAddress
        ? {
            id: selectedAddress.id,
            contact: selectedAddress.contact,
            countryRegion: selectedAddress.countryRegion,
            firstName: selectedAddress.firstName,
            lastName: selectedAddress.lastName,
            address: selectedAddress.address,
            city: selectedAddress.city,
            state: selectedAddress.state,
            mobile: selectedAddress.mobile,
            pincode: selectedAddress.pincode,
            save_as: selectedAddress.save_as
        }
        : {
            email: userDetails?.email,
            mobile: userDetails?.mobile,
        };

    form.setFieldsValue(initialValues);

    const [formData, setFormData] = useState({
        name: "",
        emailMe: false,
        countryRegion: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        mobile: "",
        saveInfo: false,
    });

    const onSearch = (value) => { };

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleSubmit = (values) => {
        if (selectedAddress) {
            values['id'] = selectedAddress.id
            editAddress(values).then((response) => {
                dispatch(setEditedAddress(response))
                window.localStorage.setItem("selectedAddress", JSON.stringify(response));
                setIsNewAddressAdd(false);
            });
        }
        else {
            addAddress(values).then((response) => {
                window.localStorage.setItem("selectedAddress", JSON.stringify(response));
                dispatch(setAddedAddress(response))
                setIsNewAddressAdd(false);
            });
        }
    };

    return (
        <>
            <Form
                form={form}
                name="addAddressForm"
                initialValues={{ remember: true }}
                style={{ marginTop: "5px" }}
                onFinish={handleSubmit}
            >
                <Row gutter={16} style={{ marginTop: "5px" }}>
                    <Col span={24}>
                        <Item
                            name="countryRegion"
                            rules={[
                                {
                                    required: true,
                                    message: "please enter country name",
                                },
                                {
                                    pattern: "^[a-zA-Z ]+$",
                                    message: "please enter only alphabets",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" placeholder="Country name" />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Item
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your first name!",
                                },
                                {
                                    pattern: "^[a-zA-Z ]+$",
                                    message: "please enter only alphabets",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" placeholder="First Name" />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your last name!",
                                },
                                {
                                    pattern: "^[a-zA-Z ]+$",
                                    message: "please enter only alphabets",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" placeholder="Last Name" />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Item
                            name="contact"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your email or mobile!",
                                },
                                {
                                    pattern: "^([0-9]{12})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$",
                                    message: "please enter correct email or mobile",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" placeholder="Enter your email or mobile" />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your mobile!",
                                },
                                {
                                    pattern: "^[0-9]{12}$",
                                    message: "please enter correct mobile",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" placeholder="Please add 91 as prefix for mobile number" />
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Item
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Address!",
                                },
                            ]}
                        >
                            <TextArea className="address-form-inputs" placeholder="Address" />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Item
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: "city",
                                },
                                {
                                    pattern: "^[a-zA-Z ]+$",
                                    message: "please enter only alphabets",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" placeholder="City" />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item
                            name="state"
                            rules={[
                                {
                                    required: true,
                                    message: "please select state",
                                },
                            ]}
                        >
                            <Select
                                className="address-form-select"
                                showSearch
                                placeholder="Select a state"
                                optionFilterProp="children"
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={states}
                                suffixIcon={<CaretDownOutlined className="address-form-select-arrow" />}
                            />
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item
                            name="pincode"
                            rules={[
                                {
                                    required: true,
                                    message: "Pincode",
                                }
                            ]}
                        >
                            <Input className="address-form-inputs" type="number" ref={inputRef} onKeyDown={handleKeyDown} placeholder="PIN code" />
                        </Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Item
                            name="save_as"
                            rules={[
                                {
                                    required: true,
                                    message: "please select address type",
                                },
                            ]}
                        >
                            <Select
                                className="address-form-select"
                                placeholder="Select address type"
                                suffixIcon={<CaretDownOutlined className="address-form-select-arrow" />}
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Option value="Home">Home</Option>
                                <Option value="Work">Work</Option>
                            </Select>
                        </Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col span={24}>
                        <Item>
                            <Button
                                className="fill-btn"
                                htmlType="submit"
                            >
                                Save Address
                            </Button>
                            <Button
                                className='outline-btn close-btn'
                                onClick={() => setIsNewAddressAdd(false)}
                                style={{ height: '48px', marginLeft: '10px' }}
                            >
                                Close
                            </Button>
                        </Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
