import { useState } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Typography,
  Pagination,
  Avatar,
  Modal,
  Rate,
  Input,
  Form,
  message,
} from "antd";
import productApiService from "../../api/productApiService";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";

const { Option } = Select;
const { Text } = Typography;

const ProductDetailReview = ({ productData, getProducts }) => {
  const { product_id } = useParams();
  const [reviewModal, setReviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const authState = useSelector((state) => (state ? state.auth : []));
  const options1 = [
    { id: 1, value: "Option 1", label: "Option 1 Label" },
    { id: 2, value: "Option 2", label: "Option 2 Label" },
  ];

  const options2 = [
    { id: 3, value: "Option 3", label: "Option 3 Label" },
    { id: 4, value: "Option 4", label: "Option 4 Label" },
  ];

  const options3 = [
    { id: 5, value: "Option 5", label: "Option 5 Label" },
    { id: 6, value: "Option 6", label: "Option 6 Label" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const AddReview = async (body) => {
    if (authState.authToken !== null) {
      setIsLoading(true);
      let values = {
        id: product_id,
        username: authState?.userDetails?.full_name,
        rating: body.rating,
        review: body.review,
        dateReviewed: new Date().toLocaleDateString(),
      };
      try {
        const { data } = await productApiService.patch(
          "/product/review",
          values
        );
        if (data) {
          form.resetFields();
          getProducts(product_id);
          setReviewModal(false);
          setIsLoading(false);
        }
      } catch (error) {
        message.error("Failed to add a review!");
        setIsLoading(false);
      }
    } else {
      message.error("User not logged in!");
    }
  };
  return (
    <>
      <Modal
        forceRender
        title="Write a review"
        open={reviewModal}
        confirmLoading={isLoading}
        onCancel={() => setReviewModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            htmlType="submit"
            form="reviewForm"
            style={{ backgroundColor: "#04b2a9" }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} id="reviewForm" onFinish={AddReview}>
          <Row>
            <Col span={24} style={{ margin: "10px 0" }}>
              <label>Rating</label>
            </Col>
            <Col span={24}>
              <Form.Item
                name="rating"
                rules={[{ required: true, message: "Please give a rating!" }]}
              >
                <Rate />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "10px 0" }}>
            <Col span={24}>
              <label htmlFor="">Review</label>
            </Col>
            <Col span={24}>
              <Form.Item name="review">
                <Input.TextArea name="review" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div style={{ paddingTop: "30px", fontWeight: 600 }}>
        <div
          style={{ borderBottom: "1px solid #ccc", marginTop: "40px" }}
        ></div>
        {productData?.reviews?.slice(startIndex, endIndex).map((review) => (
          <div
            key={review.id}
            style={{
              borderBottom: "1px solid #ccc",
              marginTop: "30px",
              paddingBottom: "30px",
              display: "flex",
            }}
          >
            <div style={{ minWidth: "48px", marginRight: "20px" }}>
              <Avatar shape="circle" size={48}>
                {" "}
                {review.username?.slice(0, 1)}
              </Avatar>
            </div>
            <div>
              <Typography>
                <Text style={{ fontWeight: "bold", marginRight: "4px" }}>
                  {review.username}
                </Text>
                <span style={{ color: "black" }}>•</span>
                <Text
                  style={{
                    marginRight: "4px",
                    marginLeft: "4px",
                    color: "gray",
                    fontWeight: "normal",
                  }}
                >
                  {review.dateReviewed}
                </Text>
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                <Rate
                  disabled
                  defaultValue={review.rating}
                  style={{ color: "#FF902D", marginLeft: "-20px" }}
                  character={
                    <span style={{ fontSize: "20px", letterSpacing: "-8px" }}>
                      &#9733;
                    </span>
                  }
                />
              </Typography>
              <Typography style={{ color: "#777586", fontWeight: "normal" }}>
                {review.review}
              </Typography>
            </div>
          </div>
        ))}
        <div style={{ paddingTop: "50px" }}>
          {/* Button positioned at top-right corner */}
          <div style={{ position: "relative" }}>
            {/* <Button
              // htmlType="submit"
              onClick={() => {
                setReviewModal(true);
                form.setFieldsValue({
                  rating: 0,
                  review: "",
                });
              }}
              disabled={authState.authToken === null}
              style={
                authState.authToken === null
                  ? {
                      color: "gray",
                      height: "48px",
                      width: "12%",
                    }
                  : {
                      backgroundColor: "#04b2a9",
                      color: "white",
                      height: "48px",
                      width: "12%",
                    }
              }
            >
              Write a Review
            </Button> */}
            <Pagination
              current={currentPage}
              total={productData?.reviews?.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              style={{
                position: "absolute",
                top: "-20px",
                right: "-30px",
                zIndex: 1,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailReview;
