import { Row, Col, Typography } from 'antd';
import './layoutStyle/productOfferBanner.css';
import ProductSearchInput from '../Products/ProductSearchInput';
import ProductSortInput from '../Products/ProductSortInput';

const { Text } = Typography;

const ProductOfferBanner = ({
  width,
  height,
  title,
  description,
  buttonText,
  onTextEntered,
  onSortingChange
}) => {
  return (
    <Row className="product-offer-banner" style={{ height: height ? height : '', alignItems: "center", placeItems: "center", }}>
      {/* <Col xs={24} md={14} lg={14} className="product-offer-banner-left">
        <Col className="product-offer-banner-content">
          <Text className="product-offer-banner-title">{title}</Text>
          <Text className="product-offer-banner-description">{description}</Text>
          <Button className="product-offer-banner-explore-btn">{buttonText}</Button>
        </Col>
      </Col>

      <Col xs={24} md={10} lg={10} className="product-offer-banner-right">
        <Image src={vector2} className="product-offer-banner-vector2" preview={false} />
      </Col> */}

      <Col xs={24} md={width > 800 ? 10 : 24} lg={10} className="product-offer-banner-left">
        {width > 800 && <Text className="product-offer-banner-title">{title}</Text>}
      </Col>
      <Col xs={24} md={10} lg={8} className="product-offer-banner-middle">
        <ProductSearchInput onTextEntered={onTextEntered} />
      </Col>
      {width > 800 && (
        <>

          <Col xs={24} md={4} lg={6} className="product-offer-banner-end">
            <ProductSortInput onSortingChange={onSortingChange} />
          </Col>
        </>
      )}
    </Row>
  );
};

export default ProductOfferBanner;
