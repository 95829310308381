import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import './Filters.css';
import { useSelector } from 'react-redux';

const CategoryFilter = ({ onCategoryChange, isResetFilter }) => {
	const categoryState = useSelector((state) =>
		state ? state.product.categories : []
	);
	const categoryOptions =
		categoryState.length > 0
			? categoryState.map((e) => {
					return e.name;
			  })
			: [];
	const [selectedCategories, setSelectedCategories] = useState([]);

	const handleCategoryChange = (selected) => {
		setSelectedCategories(selected);

		if (onCategoryChange) {
			onCategoryChange(selected);
		}
	};

	useEffect(() => {
		if (isResetFilter) {
			setSelectedCategories([]);
		}
	}, [isResetFilter]);

	return (
		<div style={{ marginBottom: '16px' }}>
			<h4 className="filter-header">Category</h4>
			<Checkbox.Group
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					marginTop: '10px',
					fontSize: '15px',
					fontWeight: '700',
					color: '#fff',
				}}
				className="custom-checkbox"
				options={categoryOptions}
				value={selectedCategories}
				onChange={handleCategoryChange}
			/>
		</div>
	);
};
export default CategoryFilter;
