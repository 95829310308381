import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Row,
	Rate,
	Typography,
	Layout,
	message,
	Spin,
	Divider,
	Empty,
	Collapse,
} from 'antd';
import './userStyle/style.css';
import { Card } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { getOrders } from '../../api/order';
import { formatDate } from '../../utils/data-time';
import { createReview } from '../../api/product';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { formatToRupees } from '../../utils/formatToRupees';
import { RightOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;

const OrderPage = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [orders, setOrders] = useState([]);
	const [showReview, setShowReview] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isOrdersLoading, setIsOrdersLoading] = useState(false);

	const [form] = useForm();
	const authState = useSelector((state) => (state ? state.auth : []));

	const handleShowReviewButtonClick = (id) => {
		setShowReview((prevState) => ({
			...prevState,
			[id]: !prevState[id] || false,
		}));
	};

	const handleFormSubmit = async (body, productId) => {
		if (authState.authToken !== null) {
			setIsLoading(true);
			let values = {
				id: productId,
				username:
					authState?.userDetails?.full_name ||
					authState?.userDetails?.email ||
					authState?.userDetails?.mobile ||
					'User',
				rating: body.rating,
				review: body.review,
				dateReviewed: JSON.stringify(new Date().getTime()),
			};
			try {
				const { data } = await createReview(values);
				if (data) {
					form.resetFields();
					setShowReview(false);
					setIsLoading(false);
				}
			} catch (error) {
				message.error('Failed to add a review!');
				setIsLoading(false);
			}
		} else {
			message.error('User not logged in!');
		}
	};

	const handleReviewClick = () => {
		setShowReview(!showReview);
	};
	const handleViewItemClick = (productId) => {
		navigate(`/product/${productId}`);
	};

	const navigate = useNavigate();

	const checkUserLoggedIn = () => {
		const otpless_user_token = JSON.parse(
			localStorage.getItem('otpless_user_token')
		);

		// if (!otpless_user_token) {
		//   navigate("/eStore");
		// }
	};

	const fetchOrders = async () => {
		setIsOrdersLoading(true);
		const orders = await getOrders();
		if (orders) {
			const sortedOrders = orders.sort((a, b) => {
				const dateA = new Date(a.createdAt).getTime();
				const dateB = new Date(b.createdAt).getTime();
				return dateB - dateA;
			});
			setOrders(orders);
		}
		setIsOrdersLoading(false);
	};

	useEffect(() => {
		checkUserLoggedIn();
		fetchOrders();
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleWindowResize);

		// Clean up the event listener
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [windowWidth]);

	const RenderOrderData = ({ order, orderItem, formKey }) => {
		return (
			<>
				{windowWidth > 800 ? (
					<Row style={{ padding: '5px 0' }}>
						<Col span={12}>
							<Row type="flex" justify="center" align="top">
								<Col
									xs={24}
									md={8}
									style={{
										textAlign: 'center',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										backgroundColor: '#f2f2f2',
										padding: '10px',
										borderRadius: '8px',
									}}
								>
									<img
										src={orderItem.productImages.split(',')[0]}
										alt={orderItem.name}
										style={{
											width: '100%',
											height: '100%',
											maxHeight: '200px',
											objectFit: 'contain',
										}}
									/>
								</Col>
								<Col xs={24} md={14}>
									<div
										style={{
											marginLeft: '25px',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'start',
											gap: '10px',
										}}
									>
										<Text className="order-item-title">
											{orderItem.name?.length > 8
												? orderItem.name.slice(0, 10) + '...'
												: orderItem.name}
										</Text>
										<Text style={{ fontSize: '16px', color: '#00ffff' }}>
											{orderItem?.brandName}
										</Text>
										<Text className="order-item-status">
											{orderItem?.selectedColor}
										</Text>
										<div style={{ margin: '10px 0' }}>
											<Button
												className="basic-btn"
												onClick={() =>
													handleViewItemClick(orderItem.product_id)
												}
											>
												View Item
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col
							span={12}
							style={{
								textAlign: 'right',
							}}
						>
							<div>
								<Text className="order-item-status">
									{order.status} {order.deliveryDate}
								</Text>
							</div>
							{order.status === 'Delivered' && (
								<Button
									className="basic-btn"
									style={{ marginTop: '15px' }}
									onClick={() =>
										handleShowReviewButtonClick(orderItem.product_id + order.id)
									}
								>
									Write Review
								</Button>
							)}
						</Col>
					</Row>
				) : (
					<Row style={{ padding: '20px 0' }}>
						<Col>
							<Row type="flex" justify="center" align="top">
								<Col
									xs={24}
									md={18}
									style={{
										textAlign: 'center',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										padding: '10px',
										borderRadius: '10px',
										backgroundColor: '#f2f2f2',
									}}
								>
									<img
										src={orderItem.productImages.split(',')[0]}
										alt={orderItem.name}
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'contain',
											maxHeight: '100px',
										}}
									/>
								</Col>
								<Col xs={24} md={18}>
									<div className="order-btn-container">
										<Col
											xs={24}
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'start',
												gap: '15px',
											}}
										>
											<Text
												className="order-item-title"
												style={{ fontSize: '1.2rem', marginY: '10px' }}
											>
												{orderItem.name?.length > 8
													? orderItem?.name?.slice(0, 10) + '....'
													: orderItem.name}
											</Text>
											<Text
												style={{
													color: '#00ffff',
													fontSize: '14px',
													marginY: '8px',
												}}
											>
												{orderItem?.brandName}
											</Text>
											<Text
												style={{
													fontSize: '14px',
												}}
											>
												{orderItem?.selectedColor}
											</Text>
										</Col>

										<Row style={{ margin: '7px 0', width: '100%' }}>
											<Col span={12}>
												<Button
													className="basic-btn"
													style={{ height: '45px' }}
													onClick={() =>
														handleViewItemClick(orderItem.product_id)
													}
												>
													View Item
												</Button>
											</Col>
											<Col span={12}>
												<Button
													className="basic-btn mb-basic-btn"
													onClick={() =>
														handleShowReviewButtonClick(
															orderItem.product_id + order.id
														)
													}
													style={{ height: '45px', padding: '10px' }}
												>
													Write Review
												</Button>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				)}

				{showReview[orderItem.product_id + order.id] && (
					<>
						<Divider />
						<Form
							form={form}
							key={formKey}
							name="profile_form"
							onFinish={(values) =>
								handleFormSubmit(values, orderItem.product_id)
							}
							layout="vertical"
							className="give-rating-form"
						>
							<Row gutter={32}>
								<Col xs={24} md={16}>
									<Form.Item name="review">
										<TextArea
											placeholder="Write your review here"
											rows={4}
											className="address-form-input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={8}>
									<Form.Item
										rules={[
											{
												required: true,
												message: 'Please give a rating!',
											},
										]}
										name="rating"
										label="Overall Rating"
									>
										<Rate style={{ fontSize: 24, color: '#FF8F28' }} />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item
								style={{ paddingTop: '20px' }}
								className="button-container"
							>
								<Button
									className="outline-btn"
									style={{ height: '50px' }}
									onClick={() =>
										handleShowReviewButtonClick(orderItem.product_id + order.id)
									}
								>
									Discard
								</Button>
								<Button
									type="default"
									htmlType="submit"
									className="fill-btn"
									style={{ margin: '0 20px' }}
								>
									{isLoading ? <Spin /> : 'Add Review'}
								</Button>
							</Form.Item>
						</Form>
					</>
				)}
			</>
		);
	};

	const panelStyle = {
		marginBottom: 10,
		background: '#fff',
		borderRadius: '0px',
		border: '1px solid #f4f4f4',
		fontFamily: 'AvenirNextLTPro',
		padding: '5px',
	};

	const rpPanelStyle = {
		marginBottom: 10,
		background: '#F8F9FC',
		borderRadius: '0px',
		border: '1px solid #f4f4f4',
		fontFamily: 'AvenirNextLTPro',
	};

	const getItems = (panelStyle, orderItems, order, index1) => {
		return orderItems.map((orderItem, index) => ({
			key: index + 1,
			label: `Order item - ${orderItem.name}`,
			children: (
				<RenderOrderData
					order={order}
					orderItem={orderItem}
					formKey={`form${index1}${index}`}
				/>
			),
			style: panelStyle,
		}));
	};

	return (
		<Layout className="layout-section order-layout">
			<Title className="title-text">Your Orders</Title>
			{isOrdersLoading ? (
				<div
					style={{
						height: '55vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spin />
				</div>
			) : orders.length === 0 ? (
				<Empty
					image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
					imageStyle={{
						height: 60,
						marginTop: '50px',
					}}
					description={
						<span style={{ fontFamily: 'AvenirNextLTPro', color: '#fff' }}>
							No orders yet!
						</span>
					}
				/>
			) : (
				orders.map((order, index1) => {
					return (
						<>
							{windowWidth > 800 ? (
								<Card
									key={index1}
									style={{
										margin: '20px',
									}}
								>
									<Row>
										<Col span={12}>
											<Row>
												<Col span={8}>
													<Text className="title-help-text">ORDER PLACED</Text>
													<div>
														<Text className="order-details-text">
															{formatDate(order.createdAt)}
														</Text>
													</div>
												</Col>
												<Col span={8}>
													<Text className="title-help-text">TOTAL</Text>
													<div>
														<Text className="order-details-text">
															₹ {formatToRupees(order.amount)}
														</Text>
													</div>
												</Col>
												<Col span={8}>
													<Text className="title-help-text">SHIP TO</Text>
													<div>
														<Text className="order-details-text">
															{`${order?.address?.firstName} ${order?.address?.lastName}, ${order?.address?.city}, ${order?.address?.state}`}
														</Text>
													</div>
												</Col>
											</Row>
										</Col>
										<Col span={12} style={{ textAlign: 'right' }}>
											<Text className="title-help-text">
												ORDER # {order.id}
											</Text>
										</Col>
									</Row>
									<Divider />
									{order['order_items']?.length > 1 ? (
										<Collapse
											items={getItems(
												panelStyle,
												order['order_items'],
												order,
												index1
											)}
											bordered={false}
											defaultActiveKey={['1']}
											expandIconPosition="end"
											expandIcon={({ isActive }) => (
												<RightOutlined rotate={isActive ? 90 : 0} />
											)}
											style={{
												background: '#fff',
											}}
										/>
									) : (
										order['order_items']?.map((orderItem, index2) => {
											const formKey = `form${index1}${index2}`;
											return (
												<RenderOrderData
													order={order}
													orderItem={orderItem}
													formKey={formKey}
												/>
											);
										})
									)}
								</Card>
							) : (
								<Card
									key={index1}
									style={{
										margin: '20px 10px',
									}}
									className="order-card"
								>
									<div style={{ marginBottom: '10px' }}>
										<Text className="title-help-text">ORDER # {order.id}</Text>
									</div>
									<div>
										<Text className="title-help-header">STATUS</Text>
										<div>
											<Text className="order-item-status">
												{order.status}{' '}
												{formatDate(order.estimated_delivery_date)}
											</Text>
										</div>
									</div>
									<Row style={{ margin: '15px 0' }}>
										<Col xs={12} md={16}>
											<Text className="title-help-text">ORDER PLACED</Text>
											<div>
												<Text className="order-details-text">
													{formatDate(order.createdAt)}
												</Text>
											</div>
										</Col>
										<Col xs={12} md={6}>
											<Text className="title-help-text">TOTAL</Text>
											<div>
												<Text className="order-details-text">
													₹ {formatToRupees(order.amount)}
												</Text>
											</div>
										</Col>
										<Col style={{ margin: '15px 0' }}>
											<Text className="title-help-text">SHIP TO</Text>
											<div>
												<Text className="order-details-text">
													{`${order?.address?.firstName} ${order?.address?.lastName}`}
												</Text>
											</div>
										</Col>
									</Row>
									{order['order_items']?.length > 1 ? (
										<Collapse
											items={getItems(
												rpPanelStyle,
												order['order_items'],
												order,
												index1
											)}
											bordered={false}
											defaultActiveKey={['1']}
											className="order-item-collapse"
											expandIconPosition="end"
											expandIcon={({ isActive }) => (
												<RightOutlined rotate={isActive ? 90 : 0} />
											)}
											style={{
												background: '#F8F9FC',
											}}
										/>
									) : (
										order['order_items']?.map((orderItem, index2) => {
											const formKey = `form${index1}${index2}`;
											return (
												<RenderOrderData
													order={order}
													orderItem={orderItem}
													formKey={formKey}
												/>
											);
										})
									)}
								</Card>
							)}
						</>
					);
				})
			)}
		</Layout>
	);
};

export default OrderPage;
