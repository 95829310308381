import orderApiService from "./orderApiService";

// Create order
export const createOrder = async (payload) => {
  const order = await orderApiService.post("order", payload);
  return order;
};

export const createRazorpayOrder = async (payload) => {
  try {
    const rzpOrder = await orderApiService.post("/razorpay-order", payload);
    return rzpOrder;
  } catch (error) {
    return [];
  }
};

export const validatePayment = async (payload) => {
  try {
    const validatePayment = await orderApiService.post(
      "/razorpay-validate",
      payload
    );
    return validatePayment;
  } catch (error) {
    return [];
  }
};

// get all orders
export const getOrders = async () => {
  try {
    const order = await orderApiService.get("orders-all");
    return order.data.data;
  } catch (error) {
    // Handle the error here, log it, and return an empty array
    // console.error("Error fetching orders:", error);
    return []; // Return an empty array in case of failure
  }
};

export const bookDemo = async (payload) => {
  const book_demo = await orderApiService.post("/demo/request-demo", payload);
  return book_demo;
};

export const requestQuote = async (payload) => {
  try {
    const response = await orderApiService.post(
      "/demo/quotation/request",
      payload
    );
    return response.data.data;
  } catch (error) {
    // console.error("Error :", error);
    return [];
  }
};
